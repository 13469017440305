import React, { useState, useCallback, useEffect } from "react";
import { Button, Card, Collection, Flex, Heading, Image, View, useTheme } from "@aws-amplify/ui-react";
import { useNavigate } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
import Loading from "../../components/Loading";
import { storageAPI } from "../../api/amplifyStorage.api";
import { LazyCar } from "../../models";

const Vehicles = () => {
  const { tokens } = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated, isAuthenticating, user } = useAuth();
  const [open, setOpen] = useState(false);
  const loadClose = () => setOpen(false);
  const loadToggle = () => setOpen(!open);
  const [userCars, setUserCars] = useState([]);

  const getImage = async (key) => {
    return await storageAPI.getImage(key);
  }

  const getUserCars = async (user) => {
    let carList = await user.cars.toArray();
    
    carList = await Promise.all(carList.map(async car => {
      let carImg;
      
      if (car.photos[0]) {
        carImg = await getImage(car.photos[0]);
      }
  
      let modifications = await car.services.toArray();
      
      return {
        ...car,
        image: carImg,
        modifications
      };
    }));
  
    setUserCars(carList);
  }

  const setUserData = useCallback(async () => {
    loadToggle();
    const email = user?.attributes?.email;
    if (email) {
      const userData = user?.userData;
      await getUserCars(userData);
    } else {
      console.error('user data not found');
    }
    loadClose();
  }, []);

  const navigateToVehicleProfile = (car) => {
    navigate(`profile/${user.userData.id}/${car.id}`, { state: { carData: car } });
  };

  useEffect(() => {
    if(!user || !user?.userData) navigate("/login");
    setUserData().catch(console.error);
  }, []);

  if (isAuthenticating || !isAuthenticated) {
    return <Loading />;
  }

  return (
    <>
      <div>
        <h2>Veículos</h2>
      </div>
      <View maxWidth="100%" padding="0rem" minHeight="100vh">
        {userCars && userCars.length ? (
          <Flex
            direction={{ base: "column", large: "row" }}
            alignItems="flex-start"
            gap={tokens.space.xl}
            marginBottom="30px"
          >
            <Collection
              type="list"
              items={userCars}
              direction="row"
              gap="20px"
              wrap="wrap"
              justifyContent={"center"}
            >
              {(item, index) => (
                <Card
                  key={index}
                  borderRadius="medium"
                  maxWidth="20rem"
                  maxHeight="20rem"
                  variation="outlined"
                  textAlign={"center"}
                >
                  <Heading padding={"relative.small"} level={4}>
                    {`${(item as LazyCar).brand} ${(item as LazyCar).model} ${(item as LazyCar).year}`}
                  </Heading>
                  <Image
                    src={item['image'] || require('../../images/gt-r_r34.jpg')}
                    alt="vehicle image"
                    borderRadius={"small"}
                    onClick={() => navigateToVehicleProfile(item as LazyCar)}
                    style={{ cursor: "pointer" }}
                    maxHeight="60%"
                  />
                  <Heading level={6}>
                    {`${(item as LazyCar).plate}`}
                  </Heading>
                </Card>
              )}
            </Collection>
          </Flex>
        ) : (
          <Flex
            direction="column"
          >
            <p>Sem veículos cadastrados</p>
            <Button
              type="button"
              variation="primary"
              isFullWidth={true}
              onClick={() => navigate('/cars/new')}
            >
              Adicione um novo veículo
            </Button>
          </Flex>
        )}
      </View>
    </>
  );
};

export default Vehicles;
