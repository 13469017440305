import React from 'react';

const SportCar = () => {
    return (
        <svg fill="#000000" width="100%" height="100%" viewBox="0 0 24 24" id="sport-car-left-2" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" className="icon flat-color">
            <path id="primary" d="M20.89,9.82l-1.21-.6a7.88,7.88,0,0,0-2.63-2.3A7.76,7.76,0,0,0,13.39,6H12.25A7.57,7.57,0,0,0,7.6,7.55L5.53,9.1l-2.18.73A2,2,0,0,0,2,11.72V14a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V11.62A2,2,0,0,0,20.89,9.82Z" style={{fill: "rgb(0, 0, 0)"}}></path><path id="secondary" d="M9.5,15.5A2.5,2.5,0,1,1,7,13,2.5,2.5,0,0,1,9.5,15.5ZM17,13a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,17,13Z" style={{fill: "rgb(44, 169, 188)"}}></path>
        </svg>
    )
}

export default SportCar;