import React from "react";
import { Icon } from "@aws-amplify/ui-react";
import logo from "./images/logo-mini.png";

import {
  MdDashboard,
  MdAccountBox,
  MdCarRepair,
  MdDirectionsCar,
  MdAdminPanelSettings
} from "react-icons/md";
// import { AiFillCar, AiFillTool } from "react-icons/ai";

export const baseConfig = {
  projectLink: "/", // GitHub link in the navbar
  docsRepositoryBase: "", // base URL for the docs repository
  titleSuffix: "",
  search: true,
  header: true,
  headerText: "",
  footer: true,
  footerText: (
    <>
      <span>
        Truly Systems {new Date().getFullYear()}
      </span>
    </>
  ),

  logo: (
    <>
      <img
        src={logo}
        alt="logo"
        width="30"
        height="22"
      />
    </>
  ),
};

/// Navigation sidebar
export const appNavs = [
  {
    eventKey: "dashboard",
    icon: <Icon as={MdDashboard} />,
    title: "Dashboard",
    to: "/dashboard",
    admin: true
  },

  {
    eventKey: "admin",
    icon: <Icon as={MdAdminPanelSettings} />,
    title: "Admin",
    to: "/admin",
    admin: true
  },

  {
    eventKey: "cars",
    icon: <Icon as={MdDirectionsCar} />,
    title: "Veículos",
    to: "/cars",
    children: [
      {
        eventKey: "addCar",
        title: "Novo Veículo",
        to: "/cars/new",
      },
    ],
  },

  {
    eventKey: "modifications",
    icon: <Icon as={MdCarRepair} />,
    title: "Modificações",
    to: "/modifications",
    children: [
      {
        eventKey: "addModification",
        title: "Nova Modificação",
        to: "/modifications/new",
      },
    ],
  },

  // {
  //   eventKey: "tables",
  //   icon: <Icon as={MdOutlineTableChart} />,
  //   title: "Tables",
  //   to: "/tables",
  //   children: [
  //     {
  //       eventKey: "basic-table",
  //       title: "Basic Table",
  //       to: "/tables",
  //     },
  //     {
  //       eventKey: "users",
  //       title: "Users Table",
  //       to: "/users-table",
  //     },
  //   ],
  // },
  // {
  //   eventKey: "forms",
  //   icon: <Icon as={MdModeEditOutline} />,
  //   title: "Forms",
  //   to: "/forms",
  //   children: [
  //     {
  //       eventKey: "form-basic",
  //       title: "Basic",
  //       to: "/forms",
  //     },
  //     {
  //       eventKey: "form-wizard",
  //       title: "Edit Form",
  //       to: "/edit-form",
  //     },
  //   ],
  // },
  {
    eventKey: "profile",
    icon: <Icon as={MdAccountBox} />,
    title: "Perfil",
    to: "/profile",
  },
];
