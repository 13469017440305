import { DataStore } from "aws-amplify"
import { Service } from "../models"

export const observeServices = async (id?:string) => {
    const subscription = DataStore.observe(Service).subscribe(({ opType, element }) => {
        console.log('MUDANÇA EM SERVICES')
        if (opType === 'INSERT') {
          console.log('Novo item inserido:', element);
        } else if (opType === 'UPDATE') {
          console.log('Item atualizado:', element);
        } else if (opType === 'DELETE') {
          console.log('Item excluído:', element);
        }
    });

    /**
     * TODO: validate unsuscribe
     */
    console.log(id, subscription)
}