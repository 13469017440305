import React from 'react';

const Drop = () => {
    return (
        <svg fill="#000000" width="100%" height="100%" viewBox="0 0 24 24" id="bloods-drop" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" className="icon flat-color">
            <path id="primary" d="M9.83,7.78c.89-1.33,2-3,3.3-5.28a1,1,0,0,1,1.74,0c1.31,2.31,2.41,4,3.3,5.28C20,10.52,21,12,21,15A7,7,0,0,1,7,15C7,12,8,10.52,9.83,7.78Z" style={{fill: "rgb(0, 0, 0)"}}></path>
            <path id="secondary" d="M5,11c.58-.88,1.31-2,2.18-3.5a1,1,0,0,1,1.74,0c.87,1.53,1.6,2.62,2.18,3.5C12.27,12.82,13,13.92,13,16A5,5,0,0,1,3,16C3,13.92,3.73,12.82,5,11Z" style={{fill: "rgb(44, 169, 188)"}}></path>
        </svg>
    )
}

export default Drop;