import React, { useEffect } from "react";
import { Hub } from "aws-amplify";

export const AmplifyHubContext = React.createContext({});

export const AmplifyHubProvider  = ({ children }:any) => {

  useEffect(() => {
    const listener = Hub.listen('datastore', 
      async ({ payload: { event, data } }) => {

        switch (event) {
          case "networkStatus":
            console.log(`User has a network connection: ${data.active}`);
            break;
          case 'subscriptionsEstablished':
            console.log(`DataStore has finished establishing its subscriptions to all models`);
            break;
          case "syncQueriesStarted":
            console.log('DataStore is about to perform its initial sync queries')
            break;
          case "modelSynced":
            console.log('Dispatched once for each model after the model instances have been synced from the cloud')
            break;
          case "syncQueriesReady":
            console.log('all models have been synced from the cloud')
            break;
          case 'ready':
            console.log('DataStore as a whole is ready, at this point all data is available')
            break;
          case "outboxMutationEnqueued":
            console.log('local change has been newly staged for synchronization with the Cloud')
            break;
          case "outboxMutationProcessed":
            console.log('local change has finished synchronization with the Cloud and is updated locally')
            break;
          case "outboxStatus":
            console.log('the DataStore starts | local mutation is enqueued into the outbox | local mutation is finished processing')
            break;
          default:
            console.log('')
        }
      });
  
      return () => {
        listener(); // Remove o ouvinte quando o componente for desmontado
      };
  }, []);

  return <AmplifyHubContext.Provider value={{}}>{children}</AmplifyHubContext.Provider>;
};