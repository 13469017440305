import React from 'react';

const Dollar = () => {
    return (
        <svg fill="#000000" width="100%" height="100%" viewBox="0 0 24 24" id="dollar-symbol" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" className="icon flat-color">
            <path id="primary" d="M13.5,11H13V6h.5A2.5,2.5,0,0,1,16,8.5a1,1,0,0,0,2,0A4.51,4.51,0,0,0,13.5,4H13V3a1,1,0,0,0-2,0V4h-.5a4.5,4.5,0,0,0,0,9H11v5h-.5A2.5,2.5,0,0,1,8,15.5a1,1,0,0,0-2,0A4.51,4.51,0,0,0,10.5,20H11v1a1,1,0,0,0,2,0V20h.5a4.5,4.5,0,0,0,0-9Zm-3,0a2.5,2.5,0,0,1,0-5H11v5Zm3,7H13V13h.5a2.5,2.5,0,0,1,0,5Z" style={{fill: "rgb(0, 0, 0)"}}></path>
        </svg>
    )
}

export default Dollar;