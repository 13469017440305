import { Button, Card, Flex, SelectField, Text, TextAreaField, View } from "@aws-amplify/ui-react";
import React, { useCallback, useEffect, useState } from "react";
import { InputTextField } from "../../components/Inputs";
import { toast } from "react-toastify";
import { serviceAPI } from "../../api/service.api";
import { storageAPI } from "../../api/amplifyStorage.api";
import Loading from "../../components/Loading";
import { redirect } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { serviceTypeAPI } from "../../api/serviceType.api";
import { LazyServiceType } from "../../models";
import DatePickerField from "../../components/DatePicker/DatePickerField";
// import { isValidDate } from "../../utils/functions";

type TModification = {
  title: string,
  description: string,
  when: string,
  isValidated: boolean,
  receipt: string,
  carServicesId: string,
  serviceTypeCarsId: string,
}

const defaultValues = {
  title: '',
  description: '',
  when: '',
  isValidated: false,
  receipt: '',
  carServicesId: '',
  serviceTypeCarsId: '',
}

const Modification = () => {
  const { isAuthenticating, isAuthenticated, user, fetchAuthUser } = useAuth();
  const [formFields, setFormFields] = useState<TModification>(defaultValues);
  const [fieldsWithError, setFieldsWithError] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [saved, setSaved] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [userCars, setUserCars] = useState([]);
  const [serviceTypes, setServiceTypes] = useState<LazyServiceType[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const loadClose = () => setOpen(false);
  const loadToggle = () => setOpen(!open);

  console.log('modification', {
    fieldsWithError, submitEnabled, user, serviceTypes
  })

  const setField = (fieldValue, fieldName) => {
    setFormFields({
        ...formFields,
        [fieldName]: fieldValue,
    });
  }

  const handleDateChange = date => {
    setSelectedDate(date);
    setFormFields({
      ...formFields,
      when: date ? new Date(date).toISOString() : new Date().toISOString(),
    });
  };

  const validFormFields = () => {
    let isValid = true;
    const errors: string[] = [];
    Object.entries(formFields).forEach(([key, value]) => {
        if (key === 'title') {
            if (value === '' || !value) {
                isValid = false;
                errors.push(key)
            }
        }
        if (key === 'description') {
            if (value === '' || !value) {
                isValid = false;
                errors.push(key)
            }
        }
        // if (key === 'when') {
        //   if (value === '' || !value) {
        //         isValid = false;
        //         errors.push(key)
        //     }
        // }
        if (key === 'carServicesId') {
            if (value === '' || !value) {
                isValid = false;
                errors.push(key)
            }
        }
        if (key === 'serviceTypeCarsId') {
          if (value === '' || !value) {
              isValid = false;
              errors.push(key)
          }
      }
    });
    console.log('fieldswitherror', errors)
    setFieldsWithError([...errors]);
    return isValid;
  }

  const mapperFormFields = async () => {
    return {
      ...formFields,
      when: new Date(formFields?.when).toISOString() || new Date().toISOString(),
    }
  }

  const getUserCars = async (user) => {
    const carList = await user?.userData?.cars?.toArray();
    setUserCars(carList);
  }

  const getServiceTypes = async () => {
    const serviceTypeList = await serviceTypeAPI.list();
    console.log('serviceTypeList',serviceTypeList)
    if (serviceTypeList) setServiceTypes(serviceTypeList);
  }

  const callAsyncFunctions = useCallback(async () => {
    await getUserCars(user);
    await getServiceTypes();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    loadToggle();
    setSubmitEnabled(false);
    if (validFormFields()) {
      const formData = await mapperFormFields();
      await submit({ ...formData });
    } else toast.warn('Todos os campos devem ser preenchidos');
    setSubmitEnabled(true);
    loadClose();
  }

  const submit = async (data) => {
    const newService = await serviceAPI.create(data);
    if (newService) {
      toast.success('Nova modificação adicionada!');
      setSaved(true);
    } else toast.error('Desculpe, ocorreu um erro');
  }

  const handleUpload = async (e) => {
    loadToggle();
    setSubmitEnabled(false);
    const file = e.target.files[0];
    if (!file) {
      loadClose();
      setSubmitEnabled(false);
      return;
    }

    const fileExtension = `${file.name.split('.').pop()}`;
    const result = await storageAPI.upload(file, fileExtension)
    if (!result) toast.error('Desculpe, ocorreu um erro ao fazer upload')
    else {
      setFormFields({ ...formFields, receipt: result.key });
      toast.success('Upload feito com sucesso!');
    }
    setSubmitEnabled(true);
    loadClose();
  }

  useEffect(() => {
    setSaved(false);
    setFormFields(defaultValues);
    fetchAuthUser();
    callAsyncFunctions();
  }, [saved]);

  useEffect(() => {
    if (isAuthenticating || !isAuthenticated) {
      redirect("/login");
    }
  }, [isAuthenticating, isAuthenticated]);

  if (isAuthenticating || !isAuthenticated) {
    return <Loading />;
  }

  return (
    <>
    {open && <Loading />}
    <div>
      <h2>Nova Modificação</h2>
    </div>
    <View columnSpan={[1, 1, 1, 2]} rowSpan={{ base: 3, large: 4 }}>
      <Card borderRadius="15px">
        <Flex as="form" direction="column" width="100%" onSubmit={handleSubmit}>
          <InputTextField
            label="Titulo"
            name="title" 
            placeholder="Exemplo: Modificação elétrica"
            isRequired={true}
            value={formFields.title}
            onChange={(event) => setField(event.target.value, event.target.name)}
          />

          <SelectField 
            label="Tipo de Modificação"
            id="serviceTypeCarsId" 
            name="serviceTypeCarsId"
            placeholder="Selecione o tipo de modificação"
            value={formFields.serviceTypeCarsId}
            onChange={(event) => {
              setField(event.target.value, event.target.name);
              setFieldsWithError([]);
            }}
          >
            {serviceTypes && serviceTypes?.map(
              (serviceType) => (
                <option
                  key={`${serviceType['id']}-select`}
                  value={serviceType['id']}
                >
                  {`${serviceType['title']}`}
                </option>
              )
            )}
          </SelectField>

          <SelectField 
            label="Veículo"
            id="carServicesId" 
            name="carServicesId"
            placeholder="Selecione o veículo"
            value={formFields.carServicesId}
            onChange={(event) => {
              setField(event.target.value, event.target.name);
              setFieldsWithError([]);
            }}
          >
            {userCars && userCars?.map(
              (car) => (
                <option
                  key={`${car['id']}-user-vehicle-select`}
                  value={car['id']}
                >
                  {`${car['brand']} ${car['model']} - ${car['plate']}`}
                </option>
              )
            )}
          </SelectField>

          <TextAreaField
            label={
              <Text>
                Descrição
                <Text as="span" fontSize="0.8rem" color="#f69697">
                  {' '}
                  (campo obrigatório)
                </Text>
              </Text>
            } 
            name="description" 
            descriptiveText="Descreva o que foi feito"
            placeholder="Exemplo: Reparo do carburador, troca de velas..."
            isRequired={true}
            value={formFields.description}
            rows={5}
            onChange={(event) => setField(event.target.value, event.target.name)}
          />

          {/* <InputTextField
            label="Data"
            name="when" 
            placeholder="05/10/2022"
            isRequired={true}
            value={formFields.when}
            onChange={(event) => setField(event.target.value, event.target.name)}
          /> */}

          <DatePickerField 
            selectedDate={selectedDate} 
            handleDateChange={handleDateChange} 
            label="Data" 
          />

          {/* TODO: limit file size. https://w3collective.com/restrict-file-size-javascript/ */}
          <div className="form-group add-file">
            <label>Adicione a Nota Fiscal</label>
            <input 
              type="file" 
              className="form-control-file" 
              id="receipt" 
              name="receipt"
              accept="image/png, image/jpeg, application/msword, application/pdf"
              onChange={handleUpload}
              onLoad={(e) => (e.target as HTMLInputElement).value = ''}
            />
          </div>

          <Button
            type="submit"
            variation="primary"
            isFullWidth={true}
            disabled={!submitEnabled}
          >
            Enviar
          </Button>
        </Flex>
      </Card>
    </View>
    </>
  );
};

export default Modification;