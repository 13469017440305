import React from 'react';

const PaintRoller = () => {
    return (
        <svg fill="#000000" width="100%" height="100%" viewBox="0 0 24 24" id="paint-roller" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" className="icon flat-color">
            <path id="secondary" d="M13,15a1,1,0,0,1-1-1V11.91l-8.2-.83a2,2,0,0,1-1.8-2V6A2,2,0,0,1,4,4H6A1,1,0,0,1,6,6H4V9.09l8.2.83a2,2,0,0,1,1.8,2V14A1,1,0,0,1,13,15Z" style={{fill: "rgb(44, 169, 188)"}}></path>
            <path id="primary" d="M20,2H7A2,2,0,0,0,5,4V6A2,2,0,0,0,7,8H20a2,2,0,0,0,2-2V4A2,2,0,0,0,20,2ZM13,22h0a2,2,0,0,1-2-2V14a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v6A2,2,0,0,1,13,22Z" style={{fill: "rgb(0, 0, 0)"}}></path>
        </svg>
    )
}

export default PaintRoller;