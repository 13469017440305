// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Car, User, Service, ServiceType } = initSchema(schema);

export {
  Car,
  User,
  Service,
  ServiceType
};