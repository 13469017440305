import { Button, Card, Flex, SearchField, SelectField, View } from "@aws-amplify/ui-react";
import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { carAPI } from "../../api/car.api";
import { InputTextField } from "../../components/Inputs";
import useAuth from "../../hooks/useAuth";
import Loading from "../../components/Loading";
import { redirect } from "react-router-dom";
import { storageAPI } from "../../api/amplifyStorage.api";
import { searchPlate } from "../../services/Fipe";
import { consultAssistant } from "../../services/CarAssistant";

enum VehiclesTypes {
  Car = 'carro',
  Bike = 'moto'
}

type TCar = {
  plate: string,
  brand: string,
  model: string,
  year: string,
  type: VehiclesTypes,
  photos: Array<string>,
  fuel: string,
  origin: string,
  city: string,
  state: string,
  chassis: string,
  chassis_status: string,
  color: string,
  power: string,
  cylinder: string,
  mounting: string,
  load_capacity: string,
  passenger_capacity: string,
  axles: string,
  pbt: string,
  cmt: string,
  bodywork: string,
  bodywork_type: string,
  engine: string,
  gearbox: string,
  rear_axle: string,
  vehicle_type: string,
  fipe_code: string,
  fipe_model_brand: string,
  fipe_value: Number | null,
}

const defaultValues = {
  plate: '', 
  brand: '', 
  model: '', 
  year: '', 
  type: VehiclesTypes.Car, 
  photos: [],
  fuel: '',
  origin: '',
  city: '',
  state: '',
  chassis: '',
  chassis_status: '',
  color: '',
  power: '',
  cylinder: '',
  mounting: '',
  load_capacity: '',
  passenger_capacity: '',
  axles: '',
  pbt: '',
  cmt: '',
  bodywork: '',
  bodywork_type: '',
  engine: '',
  gearbox: '',
  rear_axle: '',
  vehicle_type: '',
  fipe_code: '',
  fipe_model_brand: '',
  fipe_value: null,
}

const Vehicle = () => {
  const { isAuthenticating, isAuthenticated, user, fetchAuthUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [formFields, setFormFields] = useState<TCar>(defaultValues);
  const [fieldsWithError, setFieldsWithError] = useState<string[]>([]);
  const [userCars, setUserCars] = useState([]);
  const [saved, setSaved] = useState(false);

  const loadClose = () => setOpen(false);
  const loadToggle = () => setOpen(true);

  console.log('vehicle page', {
    fieldsWithError, userCars
  })
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    loadToggle();
    if (validFormFields()) {
        const formData = await mapperFormFields();
        await submit({ ...formData });
    } else toast.warn('Todos os campos devem ser preenchidos');
    loadClose();
  }

  const validFormFields = () => {
      let isValid = true;
      const errors: string[] = [];
      Object.entries(formFields).forEach(([key, value]) => {
          if (key === 'plate') {
              if (value === '' || !value) {
                  isValid = false;
                  errors.push(key)
              }
          }
          if (key === 'brand') {
              if (value === '' || !value) {
                  isValid = false;
                  errors.push(key)
              }
          }
          if (key === 'model') {
              if (value === '' || !value) {
                  isValid = false;
                  errors.push(key)
              }
          }
          if (key === 'year') {
              if (value === '' || !value) {
                  isValid = false;
                  errors.push(key)
              }
          }
      });
      setFieldsWithError([...errors]);
      return isValid;
  }

  const mapperFormFields = async () => {
      return {
          ...formFields, year: parseInt(formFields.year)
      }
  }

  const handleChangeYear = (e) => {
      const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
          setFormFields({
              ...formFields, year: e.target.value
          })
      }
  }

  const setField = (fieldValue, fieldName) => {
      setFormFields({
          ...formFields,
          [fieldName]: fieldValue,
      });
  }

  const handleSearchPlate = async () => {
    if (!formFields.plate) return false;
    if (formFields.plate.length !== 7) return false;

    setFormFields(defaultValues);

    loadToggle();
    const fipeData = await searchPlate(formFields.plate);

    if (fipeData) {
      if (fipeData.veiculo) {
        const [carBrand, carModel] = fipeData.veiculo.marca_modelo.split("/");
        const carYear = fipeData.veiculo.ano.split("/")[0];
        const fipes = fipeData.fipes[0];
        const carType = fipeData.veiculo.tipo_de_veiculo.includes('moto') 
          ? VehiclesTypes.Bike
          : VehiclesTypes.Car;

        setFormFields({
          ...formFields,
          year: carYear || fipeData.veiculo.ano,
          brand: carBrand || fipeData.veiculo.marca_modelo,
          model: carModel || fipeData.veiculo.marca_modelo,
          fuel: fipeData.veiculo.combustivel || '',
          origin: fipeData.veiculo.procedencia || '',
          city: fipeData.veiculo.municipio || '',
          state: fipeData.veiculo.uf || '',
          chassis: fipeData.veiculo.chassi || '',
          chassis_status: fipeData.veiculo.situacao_do_chassi || '',
          color: fipeData.veiculo.cor || '',
          power: fipeData.veiculo.potencia || '',
          cylinder: fipeData.veiculo.cilindradas || '',
          mounting: fipeData.veiculo.tipo_montagem || '',
          load_capacity: fipeData.veiculo.capacidade_de_carga || '',
          passenger_capacity: fipeData.veiculo.quantidade_passageiro || '',
          axles: fipeData.veiculo.quantidade_de_eixos || '',
          pbt: fipeData.veiculo.pbt || '',
          cmt: fipeData.veiculo.cmt || '',
          bodywork: fipeData.veiculo.carroceria || '',
          bodywork_type: fipeData.veiculo.tipo_carroceria || '',
          engine: fipeData.veiculo.n_motor || '',
          gearbox: fipeData.veiculo.caixa_cambio || '',
          rear_axle: fipeData.veiculo.eixo_traseiro_dif || '',
          vehicle_type: fipeData.veiculo.tipo_de_veiculo || '',
          fipe_code: fipes?.codigo || '',
          fipe_model_brand: fipes?.marca_modelo || '',
          fipe_value: fipes?.valor || null,
          type: carType,
        });

        toast.success("Busca feita com sucesso!");
        console.log(formFields)
      } else toast.warn(fipeData.message);
    } else toast.error("Ocorreu um erro ao buscar a placa.");

    loadClose();
    return false;
  }

  const handleUpload = async (e) => {
    loadToggle();
    const file = e.target.files[0];
    if (!file) {
      loadClose();
      return;
    }
    const fileExtension = `${file.name.split('.').pop()}`;
    const result = await storageAPI.upload(file, fileExtension);
    if (!result) toast.error('Desculpe, ocorreu um erro ao fazer upload');
    else {
        setFormFields({ ...formFields, photos: [...formFields.photos, result.key] });
        toast.success('Upload feito com sucesso!');
    }
    loadClose();
}

  const submit = async (data) => {
    const newCarAdded = await carAPI.create({ ...data }, user.userData);
    if (newCarAdded) {
      getAssistantInput(newCarAdded.id);
      toast.success('Novo veículo adicionado!');
      setSaved(true);
    } else toast.error('Desculpe, ocorreu um erro');
  }

  const getAssistantInput = (id) => {
    consultAssistant(id);
  }

  const getUserCars = async (user) => {
    const carList = await user?.cars?.toArray();
    setUserCars(carList);
  }

  const callAsyncFunctions = useCallback(async () => {
    await getUserCars(user);
  }, []);

  useEffect(() => {
    setSaved(false);
    fetchAuthUser();
    callAsyncFunctions();
    setFormFields(defaultValues);
    loadClose();
  }, [saved]);

  useEffect(() => {
    if (isAuthenticating || !isAuthenticated) {
      redirect("/login");
    }
  }, [isAuthenticating, isAuthenticated]);

  if (isAuthenticating || !isAuthenticated) {
    return <Loading />;
  }

  return (
    <>
    {open && <Loading />}
    <div>
      <h2>Novo Veículo</h2>
    </div>

    <View columnSpan={[1, 1, 1, 2]} rowSpan={{ base: 3, large: 4 }}>
      <Card borderRadius="15px">
        <Flex as="form" direction="column" width="100%">
          <SearchField
            label="Placa"
            placeholder="Exemplo: BRA2E23"
            name="plate"
            value={formFields.plate}
            onChange={(event) => setField(event.target.value, event.target.name)}
            onSubmit={handleSearchPlate}
            onClear={() => setFormFields({ ...formFields, plate: '' })}
            minLength={7}
            maxLength={7}
          />

          <SelectField
            label="Tipo de Veículo"
            id="type" 
            name="type"
            placeholder="Selecione o tipo de veículo"
            value={formFields.type}
            onChange={(event) => {
              setField(event.target.value, event.target.name);
              setFieldsWithError([]);
            }}
          >
            {(Object.keys(VehiclesTypes) as (keyof typeof VehiclesTypes)[]).map(
              (key) => (
                <option key={`${key}-vehicles-types`} value={key}>{VehiclesTypes[key]}</option>
              )
            )}
          </SelectField>

          <InputTextField
            label="Modelo"
            name="model" 
            placeholder="Exemplo: HB20"
            isRequired={true}
            value={formFields.model}
            onChange={(event) => setField(event.target.value, event.target.name)}
          />

          <InputTextField
            label="Marca"
            placeholder="Exemplo: Hyundai"
            isRequired={true}
            name="brand"
            value={formFields.brand}
            onChange={(event) => setField(event.target.value, event.target.name)}
          />

          <InputTextField
            label="Ano"
            placeholder="Exemplo: 2018"
            isRequired={true}
            name="year"
            value={formFields.year}
            onChange={handleChangeYear}
          />

          {/* TODO: limit file size. https://w3collective.com/restrict-file-size-javascript/ */}
          <div className="form-group add-file">
            <label>Adicione uma foto</label>
            <input 
              type="file" 
              className="form-control-file" 
              id="photo" 
              name="photo"
              accept="image/png, image/jpeg"
              onChange={handleUpload}
              onLoad={(e) => (e.target as HTMLInputElement).value = ''}
            />
          </div>

          <Button
            type="submit"
            variation="primary"
            isFullWidth={true}
            onClick={handleSubmit}
          >
            Enviar
          </Button>

        </Flex>
      </Card>
    </View>
    </>
  );
};

export default Vehicle;