import { IdentifierFieldOrIdentifierObject, ModelInit, PersistentModelMetaData } from "@aws-amplify/datastore";
import { Car, LazyCar } from "../models";
import { DataStore } from "aws-amplify";

class CarAPI {

  /**
   * Create Car
   * 
   * @param {*} data 
   */
  async create(data: ModelInit<LazyCar>, user: any) {
    try {
      console.log('Add car', { data, user })
      const car = await DataStore.query(Car, u => u.plate.eq(data.plate));
      console.log('car found', car)
      if (car && car.length > 0) throw new Error('Car with plate already exists');

      const newCar = await DataStore.save(
        new Car({
          ...data, user
        })
      );
      console.log('Car saved successfully!', newCar)
      return newCar;
    } catch (error) {
      console.log('Error saving car', error)
      return false;
    }
  }

  /**
   * Update Car
   * 
   * OBS: Models in DataStore are immutable. To update a record you must use the copyOf function to apply updates to the item's fields rather than mutating the instance directly.
   * 
   * @param {*} id 
   * @param {*} data 
   */
  async update(id: IdentifierFieldOrIdentifierObject<LazyCar, PersistentModelMetaData<LazyCar>>, data: { [x: string]: any; }) {
    try {
      const car = await DataStore.query(Car, id);
      if (car) {
        const updatedCar = await DataStore.save(
          Car.copyOf(car, updated => {
            for(const property in data) {
              updated[property] = data[property]
            }
          })
        );
        console.log('Car updated successfully!', updatedCar);
        return updatedCar;
      } else throw new Error(`Car ${id} not found`);
    } catch (error) {
      console.log('Error updating car', error)
      return false;
    }
  }

  /**
   * Get Car
   * 
   * @param {*} id 
   */
  async find(id: IdentifierFieldOrIdentifierObject<LazyCar, PersistentModelMetaData<LazyCar>>) {
    try {
      const car = await DataStore.query(Car, id);
      console.log('Car found!', car)
      return car;
    } catch (error) {
      console.log('Error getting car', error)
      return false;
    }
  }

  /**
   * Delete a Car
   * 
   * @param {*} id 
   */
  async delete(id: IdentifierFieldOrIdentifierObject<LazyCar, PersistentModelMetaData<LazyCar>>) {
    try {
      const carToDelete = await DataStore.query(Car, id);
      if (carToDelete) {
        const carDeleted = await DataStore.delete(carToDelete);
        console.log('Car deleted!', carDeleted)
        return carDeleted;
      } else throw new Error(`Car ${id} not found`);
    } catch (error) {
      console.log('Error getting car', error)
      return false;
    }
  }
}

export const carAPI = new CarAPI();