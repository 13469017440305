import React, { useCallback, useEffect } from "react";
import {
  View,
} from "@aws-amplify/ui-react";

import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { userAPI } from "../../api/user.api";

const News = () => {
  const navigate = useNavigate();
  const { isAuthenticating, isAuthenticated, user, createUserInDontExist } = useAuth();

  const deleteExtra = async () => {
    if (user && user.userData) await userAPI.removeDuplicates({ email: user.userData.email, id: user.userData.id });
  }

  const callAsyncFunctions = useCallback(async () => {
    await deleteExtra();
  }, []);

  useEffect(() => {
    if (isAuthenticating || !isAuthenticated) {
      navigate("/login", { replace: true });
    }
  }, [isAuthenticating, isAuthenticated]);

  useEffect(() => {
    if(isAuthenticated && !user?.userData) {
      createUserInDontExist();
    }
    callAsyncFunctions();
  }, [user]);

  if (isAuthenticating || !isAuthenticated) {
    return <Loading />;
  }

  return (
    <>
      <div>
        <h2>Novidades</h2>
      </div>
      <View borderRadius="6px" maxWidth="100%" padding="0rem" minHeight="100vh">
        Nada por enquanto...
      </View>
    </>
  );
};

export default News;
