/**
 * Mapped errors related to sign in and sign up
 */
export const authErrors = {
    UserNotFoundException: 'Usuário com email informado não existe.',
    NotAuthorizedException: 'Email ou senha incorreta.',
    InvalidPasswordException: 'Senha muito fraca. Tente uma senha mais complexa.',
    InvalidParameterException: 'Formato de e-mail incorreto. Por favor, insira um e-mail válido.',
    UserLambdaValidationException: 'Um erro ocorreu, por favor tente novamente. (lambda error)',
    UsernameExistsException: 'E-mail já cadastrado. Por favor, use outro e-mail.'
};