import React, { Component } from "react";
import "./DownloadBanner.css";
import { Button } from "@aws-amplify/ui-react";
import BannerLogo from "../../images/full-mobile-logo.png";

class DownloadBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      installPrompt: null,
    };

    this.installBanner = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("beforeinstallprompt", this.handleBeforeInstallPrompt);
  }
  
  componentWillUnmount() {
    window.removeEventListener("beforeinstallprompt", this.handleBeforeInstallPrompt);
  }

  handleBeforeInstallPrompt = (event) => {
    console.log('Beforeinstallprompt working, is ready to download!');
    event.preventDefault();
    this.setState({
      installPrompt: event,
    });
    this.installBanner.current.removeAttribute("hidden");
  };

  handleInstallClick = () => {
  
    const { installPrompt } = this.state;
  
    if (!installPrompt) {
      return;
    }
  
    try {
      installPrompt.prompt();
    } catch (error) {
      console.error("Erro durante a instalação:", error);
    }
  };

  handleLaterClick = () => {
    this.installBanner.current.setAttribute("hidden", "");
  };

  render() {
    return (
      <div className="banner" ref={this.installBanner} >
        <div className="textcontainer">
          <img className="logo" src={BannerLogo} />
          <div className="textdiv">
            Instale o nosso app e use sem precisar de internet!
          </div>
        </div>
        <div className="buttondiv">
          <Button className="buttonLatter" variation="link" onClick={this.handleLaterClick}>
            Mais Tarde
          </Button>
          <Button className="buttonNow" onClick={() => this.handleInstallClick()}>
            Instalar
          </Button>
        </div>
      </div>
    );
  }
}

export default DownloadBanner;