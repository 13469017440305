import React, { useEffect, useState } from "react";
import { Flex, View, useTheme, PasswordField } from "@aws-amplify/ui-react";
import { InputTextField } from "../../components/Inputs";
import Loading from "../../components/Loading";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import "../../styles/loginPage.css";
import { toast } from "react-toastify";
import LoginLogo from "../../images/full-logo-center.png";
import { envConfig } from "../../config/EnvConfig";
import { authErrors } from "../../utils/errors";

const Signup = () => {
  const navigate = useNavigate();
  const { tokens } = useTheme();
  const [signupValues, setSignupValues] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });
  const [confirmCode, setConfirmCode] = useState("");
  const [confirmMode, setConfirmMode] = useState(false);

  const setValues = (name: string, value: string) => {
    setSignupValues({
      ...signupValues,
      [name]: value,
    });
  };

  const {
    signUp,
    isAuthenticated,
    isAuthenticating,
    confirmAccount,
    federatedSignIn,
  } = useAuth();

  const handleSignup = async (provider?: string) => {

    if (!signupValues.firstName || !signupValues.lastName || !signupValues.email || !signupValues.password) {
      toast.error("Por favor, preencha todos os campos.");
      return;
    }

    try {
      if (!provider) {
        await signUp(signupValues);
        setConfirmMode(true);
      } else {
        await federatedSignIn({ provider, isSignUp: true });
      }
    } catch (error) {
      let errorMessage = authErrors[error?.['name']]
      if (errorMessage) toast.error(errorMessage);
      // else toast.error("Ocorreu um erro ao tentar logar. Por favor tente novamente mais tarde.");
      else console.log('SIGNUP ERROR', error);
    }
  };

  const handleConfirmCode = async () => {
    try {
      console.log("handleConfirmCode");
      await confirmAccount({ code: confirmCode });
      navigate("/", { replace: true });
    } catch (error) {
      console.log("Confirm ERROR", error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/", { replace: true });
    }
  }, [isAuthenticated]);

  if (isAuthenticating || isAuthenticated) {
    return <Loading />;
  }

  return (
    <>
      <View
        maxWidth="100%"
        padding="0rem"
        minHeight="100vh"
        backgroundColor="var(--amplify-colors-background-secondary)"
      >
        <Flex
          direction={{ base: "column", large: "row" }}
          alignItems="flex-start"
          gap={tokens.space.xl}
          marginBottom="10%"
          justifyContent="center"
        >
          <View
            backgroundColor="var(--amplify-colors-background-secondary)"
            borderRadius="6px"
            width={{ base: "100%", large: "100%" }}
            padding="1rem"
            display="flex"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <div className="login-content">
              <div className="d-flex login-form-content">
                <div className="p-5 shadow-sm rounded-3 login-case">
                  <div style={{ width: "100%" }}>
                    <img
                      alt="logo"
                      src={LoginLogo}
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  {!confirmMode ? (
                    <form>
                      <div className="mb-2">
                        <InputTextField
                          label="Nome"
                          name="firstName"
                          placeholder=""
                          isRequired={true}
                          value={signupValues.firstName}
                          onChange={(e) =>
                            setValues(e.target.name, e.target.value)
                          }
                        />
                      </div>

                      <div className="mb-2">
                        <InputTextField
                          label="Sobrenome"
                          name="lastName"
                          placeholder=""
                          isRequired={true}
                          value={signupValues.lastName}
                          onChange={(e) =>
                          setValues(e.target.name, e.target.value)
                          }
                        />
                      </div>

                      <div className="mb-2">
                        <InputTextField
                          label="E-mail"
                          name="email"
                          placeholder=""
                          isRequired={true}
                          aria-describedby="emailHelp"
                          value={signupValues.email}
                          onChange={(e) =>
                            setValues(e.target.name, e.target.value)
                          }
                        />
                      </div>

                      <div className="mb-2">
                        <PasswordField
                          label="Senha"
                          name="password"
                          isRequired={true}
                          value={signupValues.password}
                          onChange={(e) =>
                            setValues(e.target.name, e.target.value)
                          }
                        />
                      </div>

                      <div className="d-grid login-button-div">
                        <button
                          className="btn btn-primary btn-login-page"
                          type="button"
                          onClick={() => handleSignup()}
                        >
                          Criar
                        </button>
                      </div>
                    </form>
                  ) : (
                    <form>
                      <div className="mb-2">
                        <label htmlFor="code" className="form-label">
                          Codigo
                        </label>
                        <input
                          name="code"
                          type="text"
                          className="form-control border border-primary"
                          id="code"
                          value={confirmCode}
                          onChange={(e) => setConfirmCode(e.target.value)}
                        />
                      </div>

                      <div className="d-grid login-button-div">
                        <button
                          className="btn btn-primary btn-login-page"
                          type="button"
                          onClick={() => handleConfirmCode()}
                        >
                          Confirmar
                        </button>
                      </div>
                    </form>
                  )}

                  <div className="mt-3">
                    <p className="mb-0  text-center create-acount">
                      <Link
                        to="/login"
                        className="text-primary fw-bold"
                      >
                        Já possui conta?
                      </Link>
                    </p>
                  </div>

                  <div className="or-div">
                    <div className="ou-bar-div">
                      <div className="ou-bar" />
                    </div>

                    <div className="or-content-box">
                      <p className="mb-0  text-center create-acount">
                        Ou
                      </p>
                    </div>

                    <div className="ou-bar-div">
                      <div className="ou-bar" />
                    </div>
                  </div>

                  <div className="social-login-div">
                    <div />

                    <button
                      type="button"
                      className="btn login-btn-google btn-social-login btn-danger"
                      onClick={() => handleSignup("Google")}
                    >
                      Entrar com Google
                    </button>
                  </div>

                  <div
                    style={{
                      margin: "5px auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <p>versão {envConfig.VERSION}</p>
                  </div>
                </div>
              </div>
            </div>
          </View>
        </Flex>
      </View>
    </>
  );
};

export default Signup;
