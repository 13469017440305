import { Text, TextField } from "@aws-amplify/ui-react";
import React from "react";

const InputTextField = ({ label, isRequired, placeholder, name, value, onChange, disabled = false }) => {
  return (
    <TextField
      name={name}
      label={
        <Text>
          {label}
          {isRequired && !value && <Text as="span" fontSize="0.8rem" color="#f69697">
            {' '}
            (campo obrigatório)
          </Text>}
        </Text>
      } 
      type="text" 
      placeholder={placeholder}
      isRequired={isRequired}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default InputTextField;
