/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://kanksicvjzbrxiau2iokcdvjp4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2llop4de4jhkpblqxljlhpr7le",
    "aws_cognito_identity_pool_id": "us-east-1:6d6842f5-2db9-4fba-ab19-1056493a882f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_J19xh4cRY",
    "aws_user_pools_web_client_id": "j38s351gi5sji8roqsl8qom3m",
    "oauth": {
        "domain": "carango-social-signin-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://d2zknoci7yqp00.cloudfront.net/,https://app.mycarango.com/,http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/,https://d2zknoci7yqp00.cloudfront.net/,https://app.mycarango.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "carango-20230629140245-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://du3ust49lv1te.cloudfront.net",
    "aws_user_files_s3_bucket": "carango-app-files192702-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
