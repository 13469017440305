import axios, { AxiosResponse } from "axios"
import { envConfig } from "../config/EnvConfig"

export const searchPlate = async (plate: string) => {
    try {
        const response = await axios.post(`${envConfig.FIPE_API_HOST}`, {
            plate: plate,
        });

        return handleResponse(response);
    } catch (error) {
        // console.log(error);
        return false;
    }
}

const handleResponse = (response: AxiosResponse) => {
    const { message, errors, data } = response.data.data;

    if (message) {
        return {
            message: message.normalize("NFC"),
            errors: errors,
        }
    } else {
        return { ...data };
    }
}
