import React from 'react';
import { 
  useReactTable, 
  createColumnHelper, 
  getCoreRowModel, 
  flexRender, 
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { 
  Button,
  Card, 
  Flex, 
  SearchField, 
  SelectField, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  TextField, 
  View } from '@aws-amplify/ui-react';

type TModificationItem = {
  title: string
  modType: string
  isValidated: string
  createdAt: string
  validatedAt: string
  carPlate: string
  userEmail: string
}

const columnHelper = createColumnHelper<TModificationItem>();
const columns = [
  columnHelper.accessor(row => row.title, {
    id: 'title',
    cell: info => <i>{info.getValue()}</i>,
    header: () => <span>Titulo</span>,
  }),
  columnHelper.accessor(row => row.modType, {
    id: 'type',
    cell: info => <i>{info.getValue()}</i>,
    header: () => <span>Tipo</span>,
  }),
  columnHelper.accessor(row => row.isValidated, {
    id: 'status',
    cell: info => <i>{info.getValue()}</i>,
    header: () => <span>Status</span>,
  }),
  columnHelper.accessor(row => row.createdAt, {
    id: 'createdAt',
    cell: info => <i>{info.getValue()}</i>,
    header: () => <span>Criação</span>,
  }),
  columnHelper.accessor(row => row.validatedAt, {
    id: 'validatedAt',
    cell: info => <i>{info.getValue()}</i>,
    header: () => <span>Validação</span>,
  }),
  columnHelper.accessor(row => row.carPlate, {
    id: 'carPlate',
    cell: info => <i>{info.getValue()}</i>,
    header: () => <span>Veículo</span>,
  }),
  columnHelper.accessor(row => row.userEmail, {
    id: 'email',
    cell: info => <i>{info.getValue()}</i>,
    header: () => <span>Usuário</span>,
  }),
];


const ModificationsTable = ({ items, onClick }) => {
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const data: TModificationItem[] = items;
  const table = useReactTable({ 
    data, 
    columns, 
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      globalFilter,
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: 20,
      }
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <View columnSpan={{ base: 1, large: 3 }} rowSpan={1} width="100%">
      <Card borderRadius="15px" overflow="auto">
        <Flex>
          <SearchField
            label="Pesquisar"
            placeholder='Busque aqui...'
            value={globalFilter ?? ''}
            onChange={e => setGlobalFilter(String(e.target.value))}
            onClear={() => setGlobalFilter('')}
          />
        </Flex>
        <Table highlightOnHover={true}>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow
                key={'table-header-group-'+headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <TableCell as="th" key={'table-header-'+header.id+'-'+headerGroup.id}>
                      {header.isPlaceholder ? null : (
                        <Flex
                          {...{
                            className: header.column.getCanSort()
                              ? 'sortable-col'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <span className="pl-2">↑</span>,
                            desc: <span className="pl-2">↓</span>,
                          }[header.column.getIsSorted() as string] ?? null}
                        </Flex>
                      )}
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map(row => (
              <TableRow key={'table-row-'+row.id+'-'+row.index}>
                {row.getVisibleCells().map(cell => (
                  <TableCell 
                    key={'table-cell-'+cell.id+'-'+row.id}
                    onClick={() => onClick(cell.row.original)}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          width="max-content"
        >
          <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <SelectField
              label='Items por página'
              value={table.getState().pagination.pageSize.toString()}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </SelectField>
          </Flex>
          <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              size='small'
              variation='link'
              color='overlay.80'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="w-5 h-5">{'<<'}</span>
            </Button>
            <Button
              size='small'
              variation='link'
              color='overlay.80'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <span>{'<'}</span>
            </Button>
            <span>
              <TextField
                label=''
                min={1}
                max={table.getPageCount()}
                type="number"
                value={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
              />
              <p>de {table.getPageCount()}</p>
            </span>
            <Button
              size='small'
              variation='link'
              color='overlay.80'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <span>{'>'}</span>
            </Button>
            <Button
              size='small'
              variation='link'
              color='overlay.80'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <span>{'>>'}</span>
            </Button>
          </Flex>
        </Flex>
      </Card>
    </View>
  )
}

export default ModificationsTable;