import { Text } from '@aws-amplify/ui-react';
import ptBR from 'date-fns/locale/pt-BR';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

registerLocale('pt-BR', ptBR);

export default function DatePickerField ({ selectedDate, handleDateChange, label = '' }) {
    return (
        <>
            <Text as='label' htmlFor='date-picker-field'>{label}</Text>
            <DatePicker
                id='date-picker-field'
                showIcon
                selected={selectedDate} 
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                locale="pt-BR"
                wrapperClassName="datePicker"
                showYearDropdown
                showMonthDropdown
                useShortMonthInDropdown
                yearDropdownItemNumber={15}
                placeholderText="Selecione a data"
            />
        </>
    )
}