import React from 'react';

const Calendar = () => {
    return (
        <svg fill="#000000" width="100%" height="100%" viewBox="0 0 24 24" id="date-calendar" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" className="icon flat-color">
            <path id="primary" d="M21,7H3A1,1,0,0,0,2,8V20a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8A1,1,0,0,0,21,7Z" style={{fill: "rgb(0, 0, 0)"}}></path>
            <path id="secondary" d="M22,6V9H2V6A2,2,0,0,1,4,4H20A2,2,0,0,1,22,6ZM9,12a1,1,0,0,0-1,1v4a1,1,0,0,0,2,0V13A1,1,0,0,0,9,12Zm6,0a1,1,0,0,0-1,1v1H13V13a1,1,0,0,0-2,0v2a1,1,0,0,0,1,1h2v1a1,1,0,0,0,2,0V13A1,1,0,0,0,15,12Z" style={{fill: "rgb(44, 169, 188)"}}></path>
            <path id="primary-2" data-name="primary" d="M16,7a1,1,0,0,1-1-1V3a1,1,0,0,1,2,0V6A1,1,0,0,1,16,7ZM9,6V3A1,1,0,0,0,7,3V6A1,1,0,0,0,9,6Z" style={{fill:"rgb(0, 0, 0)"}}></path>
        </svg>
    )
}

export default Calendar;