/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getCar = /* GraphQL */ `query GetCar($id: ID!) {
  getCar(id: $id) {
    id
    user {
      id
      email
      name
      phone
      birth
      avatar
      searches
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    model
    brand
    year
    plate
    doors
    type
    photos
    services {
      nextToken
      startedAt
      __typename
    }
    fuel
    origin
    city
    state
    chassis
    chassis_status
    color
    power
    cylinder
    mounting
    load_capacity
    passenger_capacity
    axles
    pbt
    cmt
    bodywork
    bodywork_type
    engine
    gearbox
    rear_axle
    vehicle_type
    fipe_code
    fipe_model_brand
    fipe_value
    assistant_input
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userCarsId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCarQueryVariables, APITypes.GetCarQuery>;
export const listCars = /* GraphQL */ `query ListCars($filter: ModelCarFilterInput, $limit: Int, $nextToken: String) {
  listCars(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      model
      brand
      year
      plate
      doors
      type
      photos
      fuel
      origin
      city
      state
      chassis
      chassis_status
      color
      power
      cylinder
      mounting
      load_capacity
      passenger_capacity
      axles
      pbt
      cmt
      bodywork
      bodywork_type
      engine
      gearbox
      rear_axle
      vehicle_type
      fipe_code
      fipe_model_brand
      fipe_value
      assistant_input
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCarsId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListCarsQueryVariables, APITypes.ListCarsQuery>;
export const syncCars = /* GraphQL */ `query SyncCars(
  $filter: ModelCarFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCars(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      model
      brand
      year
      plate
      doors
      type
      photos
      fuel
      origin
      city
      state
      chassis
      chassis_status
      color
      power
      cylinder
      mounting
      load_capacity
      passenger_capacity
      axles
      pbt
      cmt
      bodywork
      bodywork_type
      engine
      gearbox
      rear_axle
      vehicle_type
      fipe_code
      fipe_model_brand
      fipe_value
      assistant_input
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCarsId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncCarsQueryVariables, APITypes.SyncCarsQuery>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    email
    name
    phone
    birth
    avatar
    cars {
      nextToken
      startedAt
      __typename
    }
    searches
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      name
      phone
      birth
      avatar
      searches
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      email
      name
      phone
      birth
      avatar
      searches
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const getService = /* GraphQL */ `query GetService($id: ID!) {
  getService(id: $id) {
    id
    title
    description
    when
    isValidated
    validatedAt
    receipt
    car {
      id
      model
      brand
      year
      plate
      doors
      type
      photos
      fuel
      origin
      city
      state
      chassis
      chassis_status
      color
      power
      cylinder
      mounting
      load_capacity
      passenger_capacity
      axles
      pbt
      cmt
      bodywork
      bodywork_type
      engine
      gearbox
      rear_axle
      vehicle_type
      fipe_code
      fipe_model_brand
      fipe_value
      assistant_input
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCarsId
      __typename
    }
    type {
      id
      title
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    carServicesId
    serviceTypeCarsId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServiceQueryVariables,
  APITypes.GetServiceQuery
>;
export const listServices = /* GraphQL */ `query ListServices(
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      when
      isValidated
      validatedAt
      receipt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      carServicesId
      serviceTypeCarsId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServicesQueryVariables,
  APITypes.ListServicesQuery
>;
export const syncServices = /* GraphQL */ `query SyncServices(
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncServices(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      description
      when
      isValidated
      validatedAt
      receipt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      carServicesId
      serviceTypeCarsId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncServicesQueryVariables,
  APITypes.SyncServicesQuery
>;
export const getServiceType = /* GraphQL */ `query GetServiceType($id: ID!) {
  getServiceType(id: $id) {
    id
    title
    description
    cars {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServiceTypeQueryVariables,
  APITypes.GetServiceTypeQuery
>;
export const listServiceTypes = /* GraphQL */ `query ListServiceTypes(
  $filter: ModelServiceTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listServiceTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServiceTypesQueryVariables,
  APITypes.ListServiceTypesQuery
>;
export const syncServiceTypes = /* GraphQL */ `query SyncServiceTypes(
  $filter: ModelServiceTypeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncServiceTypes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncServiceTypesQueryVariables,
  APITypes.SyncServiceTypesQuery
>;
