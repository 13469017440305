import React from "react";
import useAuth from "../hooks/useAuth";
import { Outlet, useNavigate } from "react-router-dom";

const ProtectedRouteAdmin = (props) => {
  const { isAuthenticated, isAdmin } = useAuth();
  const navigate = useNavigate();

  if (isAdmin && isAuthenticated) {
    return <Outlet {...props} />;
  }
  else if (!isAdmin && isAuthenticated) navigate(-1);
  else navigate("/login", { replace: true });
};

export default ProtectedRouteAdmin;