import { Button, Card, Flex, SelectField, View } from "@aws-amplify/ui-react";
import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { carAPI } from "../../api/car.api";
import { InputTextField } from "../../components/Inputs";
import useAuth from "../../hooks/useAuth";
import Loading from "../../components/Loading";
import { redirect, useLocation } from "react-router-dom";
import { storageAPI } from "../../api/amplifyStorage.api";

enum VehiclesTypes {
  Car = 'carro',
  Bike = 'moto'
}

type TCar = {
  plate: string,
  brand: string,
  model: string,
  year: string,
  type: VehiclesTypes,
  photos: Array<string>,
}

const defaultValues = {
  plate: '', 
  brand: '', 
  model: '', 
  year: '', 
  type: VehiclesTypes.Car, 
  photos: []
}

const VehicleEdit = () => {
  const { state: props } = useLocation();
  const { isAuthenticating, isAuthenticated, user, fetchAuthUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [formFields, setFormFields] = useState<TCar>(defaultValues);
  const [fieldsWithError, setFieldsWithError] = useState<string[]>([]);
  const [userCars, setUserCars] = useState([]);
  const [saved, setSaved] = useState(false);

  const loadClose = () => setOpen(false);
  const loadToggle = () => setOpen(true);

  console.log('edit vehicle page', {
    fieldsWithError, userCars, props
  })
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    loadToggle();
    if (validFormFields()) {
        const formData = await mapperFormFields();
        await submit(props.carData.id, { ...formData });
    } else toast.warn('Todos os campos devem ser preenchidos');
    loadClose();
  }

  const validFormFields = () => {
      let isValid = true;
      const errors: string[] = [];
      Object.entries(formFields).forEach(([key, value]) => {
          if (key === 'plate') {
              if (value === '' || !value) {
                  isValid = false;
                  errors.push(key)
              }
          }
          if (key === 'brand') {
              if (value === '' || !value) {
                  isValid = false;
                  errors.push(key)
              }
          }
          if (key === 'model') {
              if (value === '' || !value) {
                  isValid = false;
                  errors.push(key)
              }
          }
          if (key === 'year') {
              if (value === '' || !value) {
                  isValid = false;
                  errors.push(key)
              }
          }
      });
      setFieldsWithError([...errors]);
      return isValid;
  }

  const mapperFormFields = async () => {
    return {
        ...formFields, year: parseInt(formFields.year)
    }
  }

  const handleChangeYear = (e) => {
      const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
          setFormFields({
              ...formFields, year: e.target.value
          })
      }
  }

  const setField = (fieldValue, fieldName) => {
      setFormFields({
          ...formFields,
          [fieldName]: fieldValue,
      });
  }

  const submit = async (id, data) => {
      const newCarAdded = await carAPI.update(id, data);
      if (newCarAdded) {
          toast.success('Veículo editado');
          setSaved(true);
      } else toast.error('Desculpe, ocorreu um erro');
  }

  const getUserCars = async (user) => {
    const carList = await user?.cars?.toArray();
    setUserCars(carList);
  }

  const handleUpload = async (e) => {
    loadToggle();
    const file = e.target.files[0];
    if (!file) {
      loadClose();
      return;
    }
    const fileExtension = `${file.name.split('.').pop()}`;
    const result = await storageAPI.upload(file, fileExtension);
    if (!result) toast.error('Desculpe, ocorreu um erro ao fazer upload');
    else {
        setFormFields({ ...formFields, photos: [...formFields.photos, result.key] });
        toast.success('Upload feito com sucesso!');
    }
    loadClose();
  }

  const callAsyncFunctions = useCallback(async () => {
    await getUserCars(user);
  }, []);

  useEffect(() => {
    setSaved(false);
    fetchAuthUser();
    callAsyncFunctions();
  }, [saved]);

  useEffect(() => {
    if (isAuthenticating || !isAuthenticated || !props || !props?.carData) {
      redirect("/login");
    }

    setFormFields(props.carData);
    callAsyncFunctions();
    loadClose();
  }, [isAuthenticating, isAuthenticated]);

  if (isAuthenticating || !isAuthenticated) {
    return <Loading />;
  }

  return (
    <>
    {open && <Loading />}
    <div>
      <h2>Editar</h2>
    </div>

    <View columnSpan={[1, 1, 1, 2]} rowSpan={{ base: 3, large: 4 }}>
      <Card borderRadius="15px">
        <Flex as="form" direction="column" width="100%" onSubmit={handleSubmit}>
          <SelectField label="Tipo de Veículo">
            {(Object.keys(VehiclesTypes) as (keyof typeof VehiclesTypes)[]).map(
              (key) => (
                <option key={`${key}-vehicles-types`} value={key}>{VehiclesTypes[key]}</option>
              )
            )}
          </SelectField>

          <InputTextField
            label="Modelo"
            name="model" 
            placeholder="Exemplo: HB20"
            isRequired={true}
            value={formFields.model}
            onChange={(event) => setField(event.target.value, event.target.name)}
          />

          <InputTextField
            label="Marca"
            placeholder="Exemplo: Hyundai"
            isRequired={true}
            name="brand"
            value={formFields.brand}
            onChange={(event) => setField(event.target.value, event.target.name)}
          />

          <InputTextField
            label="Placa"
            placeholder="Exemplo: BRA2E23"
            isRequired={true}
            name="plate"
            value={formFields.plate}
            onChange={(event) => setField(event.target.value, event.target.name)}
          />

          <InputTextField
            label="Ano"
            placeholder="Exemplo: 2018"
            isRequired={true}
            name="year"
            value={formFields.year}
            onChange={handleChangeYear}
          />

          {formFields?.['fuel'] && <InputTextField
            label="Combustível"
            placeholder=""
            isRequired={false}
            name="fuel"
            value={formFields?.['fuel']}
            disabled={true}
            onChange={null}
          />}
          {formFields?.['color'] && <InputTextField
            label="Cor"
            placeholder=""
            isRequired={false}
            name="color"
            value={formFields?.['color']}
            disabled={true}
            onChange={null}
          />}
          {formFields?.['fipe_value'] && <InputTextField
            label="Valor FIPE"
            placeholder=""
            isRequired={false}
            name="fipe_value"
            value={formFields?.['fipe_value']}
            disabled={true}
            onChange={null}
          />}
          {formFields?.['origin'] && <InputTextField
            label="Procedência"
            placeholder=""
            isRequired={false}
            name="origin"
            value={formFields?.['origin']}
            disabled={true}
            onChange={null}
          />}

          {/* TODO: limit file size. https://w3collective.com/restrict-file-size-javascript/ */}
          <div className="form-group add-file">
            <label>Adicione uma foto</label>
            <input 
              type="file" 
              className="form-control-file" 
              id="photo" 
              name="photo"
              accept="image/png, image/jpeg"
              onChange={handleUpload}
              onLoad={(e) => (e.target as HTMLInputElement).value = ''}
            />
          </div>

          <Button
            type="submit"
            variation="primary"
            isFullWidth={true}
          >
            Enviar
          </Button>

        </Flex>
      </Card>
    </View>
    </>
  );
};

export default VehicleEdit;