/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createCar = /* GraphQL */ `mutation CreateCar(
  $input: CreateCarInput!
  $condition: ModelCarConditionInput
) {
  createCar(input: $input, condition: $condition) {
    id
    user {
      id
      email
      name
      phone
      birth
      avatar
      searches
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    model
    brand
    year
    plate
    doors
    type
    photos
    services {
      nextToken
      startedAt
      __typename
    }
    fuel
    origin
    city
    state
    chassis
    chassis_status
    color
    power
    cylinder
    mounting
    load_capacity
    passenger_capacity
    axles
    pbt
    cmt
    bodywork
    bodywork_type
    engine
    gearbox
    rear_axle
    vehicle_type
    fipe_code
    fipe_model_brand
    fipe_value
    assistant_input
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userCarsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCarMutationVariables,
  APITypes.CreateCarMutation
>;
export const updateCar = /* GraphQL */ `mutation UpdateCar(
  $input: UpdateCarInput!
  $condition: ModelCarConditionInput
) {
  updateCar(input: $input, condition: $condition) {
    id
    user {
      id
      email
      name
      phone
      birth
      avatar
      searches
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    model
    brand
    year
    plate
    doors
    type
    photos
    services {
      nextToken
      startedAt
      __typename
    }
    fuel
    origin
    city
    state
    chassis
    chassis_status
    color
    power
    cylinder
    mounting
    load_capacity
    passenger_capacity
    axles
    pbt
    cmt
    bodywork
    bodywork_type
    engine
    gearbox
    rear_axle
    vehicle_type
    fipe_code
    fipe_model_brand
    fipe_value
    assistant_input
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userCarsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCarMutationVariables,
  APITypes.UpdateCarMutation
>;
export const deleteCar = /* GraphQL */ `mutation DeleteCar(
  $input: DeleteCarInput!
  $condition: ModelCarConditionInput
) {
  deleteCar(input: $input, condition: $condition) {
    id
    user {
      id
      email
      name
      phone
      birth
      avatar
      searches
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    model
    brand
    year
    plate
    doors
    type
    photos
    services {
      nextToken
      startedAt
      __typename
    }
    fuel
    origin
    city
    state
    chassis
    chassis_status
    color
    power
    cylinder
    mounting
    load_capacity
    passenger_capacity
    axles
    pbt
    cmt
    bodywork
    bodywork_type
    engine
    gearbox
    rear_axle
    vehicle_type
    fipe_code
    fipe_model_brand
    fipe_value
    assistant_input
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userCarsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCarMutationVariables,
  APITypes.DeleteCarMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    email
    name
    phone
    birth
    avatar
    cars {
      nextToken
      startedAt
      __typename
    }
    searches
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    email
    name
    phone
    birth
    avatar
    cars {
      nextToken
      startedAt
      __typename
    }
    searches
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    email
    name
    phone
    birth
    avatar
    cars {
      nextToken
      startedAt
      __typename
    }
    searches
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createService = /* GraphQL */ `mutation CreateService(
  $input: CreateServiceInput!
  $condition: ModelServiceConditionInput
) {
  createService(input: $input, condition: $condition) {
    id
    title
    description
    when
    isValidated
    validatedAt
    receipt
    car {
      id
      model
      brand
      year
      plate
      doors
      type
      photos
      fuel
      origin
      city
      state
      chassis
      chassis_status
      color
      power
      cylinder
      mounting
      load_capacity
      passenger_capacity
      axles
      pbt
      cmt
      bodywork
      bodywork_type
      engine
      gearbox
      rear_axle
      vehicle_type
      fipe_code
      fipe_model_brand
      fipe_value
      assistant_input
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCarsId
      __typename
    }
    type {
      id
      title
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    carServicesId
    serviceTypeCarsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateServiceMutationVariables,
  APITypes.CreateServiceMutation
>;
export const updateService = /* GraphQL */ `mutation UpdateService(
  $input: UpdateServiceInput!
  $condition: ModelServiceConditionInput
) {
  updateService(input: $input, condition: $condition) {
    id
    title
    description
    when
    isValidated
    validatedAt
    receipt
    car {
      id
      model
      brand
      year
      plate
      doors
      type
      photos
      fuel
      origin
      city
      state
      chassis
      chassis_status
      color
      power
      cylinder
      mounting
      load_capacity
      passenger_capacity
      axles
      pbt
      cmt
      bodywork
      bodywork_type
      engine
      gearbox
      rear_axle
      vehicle_type
      fipe_code
      fipe_model_brand
      fipe_value
      assistant_input
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCarsId
      __typename
    }
    type {
      id
      title
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    carServicesId
    serviceTypeCarsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateServiceMutationVariables,
  APITypes.UpdateServiceMutation
>;
export const deleteService = /* GraphQL */ `mutation DeleteService(
  $input: DeleteServiceInput!
  $condition: ModelServiceConditionInput
) {
  deleteService(input: $input, condition: $condition) {
    id
    title
    description
    when
    isValidated
    validatedAt
    receipt
    car {
      id
      model
      brand
      year
      plate
      doors
      type
      photos
      fuel
      origin
      city
      state
      chassis
      chassis_status
      color
      power
      cylinder
      mounting
      load_capacity
      passenger_capacity
      axles
      pbt
      cmt
      bodywork
      bodywork_type
      engine
      gearbox
      rear_axle
      vehicle_type
      fipe_code
      fipe_model_brand
      fipe_value
      assistant_input
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCarsId
      __typename
    }
    type {
      id
      title
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    carServicesId
    serviceTypeCarsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteServiceMutationVariables,
  APITypes.DeleteServiceMutation
>;
export const createServiceType = /* GraphQL */ `mutation CreateServiceType(
  $input: CreateServiceTypeInput!
  $condition: ModelServiceTypeConditionInput
) {
  createServiceType(input: $input, condition: $condition) {
    id
    title
    description
    cars {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateServiceTypeMutationVariables,
  APITypes.CreateServiceTypeMutation
>;
export const updateServiceType = /* GraphQL */ `mutation UpdateServiceType(
  $input: UpdateServiceTypeInput!
  $condition: ModelServiceTypeConditionInput
) {
  updateServiceType(input: $input, condition: $condition) {
    id
    title
    description
    cars {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateServiceTypeMutationVariables,
  APITypes.UpdateServiceTypeMutation
>;
export const deleteServiceType = /* GraphQL */ `mutation DeleteServiceType(
  $input: DeleteServiceTypeInput!
  $condition: ModelServiceTypeConditionInput
) {
  deleteServiceType(input: $input, condition: $condition) {
    id
    title
    description
    cars {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteServiceTypeMutationVariables,
  APITypes.DeleteServiceTypeMutation
>;
