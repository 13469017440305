import { Button, Table, TableBody, TableCell, TableHead, TableRow, View } from "@aws-amplify/ui-react";
import React, { useEffect, useCallback, useState } from "react";
import Loading from "../../components/Loading";
import useAuth from "../../hooks/useAuth";
import { redirect, useNavigate } from "react-router-dom";

const Modifications = () => {
  const navigate = useNavigate();
  const { isAuthenticating, isAuthenticated, user } = useAuth();
  const [tableData, setTableData] = useState([]);
  // const [open, setOpen] = useState(false);
  // const [userCars, setUserCars] = useState([]);

  // const loadClose = () => setOpen(false);
  // const loadToggle = () => setOpen(!open);

  const buildTableData = async () => {
    const cars = await user?.userData?.cars?.toArray();
    let modificationsList = [];
    modificationsList = await Promise.all(
      cars.map(async (car) => {
        const carModList = await Promise.all(
          (await car.services.toArray()).map(async (modification) => {
            const modType = await modification.type;
            const modTypeData = {
              title: modType?.title,
              description: modType?.description,
            };
            return { ...modification, carData: car, typeData: modTypeData };
          })
        );
        return carModList;
      })
    ) as [];
    setTableData(modificationsList.flat());
  }
  
  const navigateToEditPage = (service) => {
    navigate(`/modifications/edit/${service.id}`, { state: { serviceData: service } });
  };


  const callAsyncFunctions = useCallback(async () => {
    await buildTableData();
  }, []);

  useEffect(() => {
    if (isAuthenticating || !isAuthenticated) {
      redirect("/login");
    }
    callAsyncFunctions();
  }, [isAuthenticating, isAuthenticated]);

  if (isAuthenticating || !isAuthenticated) {
    return <Loading />;
  }

  return (
    <>
    <div>
      <h2>Modificações</h2>
    </div>

    <View columnSpan={[1, 1, 1, 2]} rowSpan={{ base: 3, large: 4 }}>
    {tableData && tableData.length ? <Table caption="" highlightOnHover={false}>
        <TableHead>
          <TableRow>
            <TableCell as="th">Modificação</TableCell>
            <TableCell as="th">Tipo</TableCell>
            <TableCell as="th">Descrição</TableCell>
            <TableCell as="th">Data</TableCell>
            <TableCell as="th">Status</TableCell>
            <TableCell as="th">Veículo</TableCell>
            <TableCell as="th"></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {tableData && tableData.length && tableData?.map((item) => {
            return (
              <TableRow
                key={item["id"] + 'table-services-item'}
              >
                <TableCell>{item?.['title']}</TableCell>
                <TableCell>{item?.['typeData']?.['title']}</TableCell>
                <TableCell>{item['description']}</TableCell>
                <TableCell>{item['when']}</TableCell>
                <TableCell>{item['isValidated'] ? 'Validado' : 'A validar'}</TableCell>
                <TableCell>{item?.['carData']?.['plate']}</TableCell>
                <TableCell>
                  <Button onClick={() => navigateToEditPage(item)}>Editar</Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table> : <p>Sem modificações</p>}
    </View>
    </>
  );
};

export default Modifications;