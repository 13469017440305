import React, { useEffect, useCallback, useState } from "react";
import { Menu, MenuItem, MenuButton } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { storageAPI } from "../../api/amplifyStorage.api";

const HeaderNav = () => {
  const navigate = useNavigate();
  const { signOut, user } = useAuth();
  const [avatarUrl, setAvatarUrl] = useState('');

  const handleLogout = async () => {
    try {
      // sign out user
      await signOut();
      await navigate("/login", { replace: true });
    } catch (err) {
      console.error("Auth Error: ", err);
    }
  };

  const setAvatarImage = async () => {
    const imageUrl = await getImage(user.userData.avatar);
    if (imageUrl) setAvatarUrl(imageUrl);
  }

  const getImage = async (key) => {
    return await storageAPI.getImage(key);
  }

  const callAsyncFunctions = useCallback(async () => {
    await setAvatarImage();
  }, [user?.userData?.avatar]);

  useEffect(() => {
    if (user && user.userData && user.userData.avatar) callAsyncFunctions();
  }, [callAsyncFunctions, user?.userData?.avatar]);

  return (
    <>
      <Menu
        menuAlign="end"
        trigger={
          <MenuButton variation="menu">
            <div className="header-avatar">
              <img alt="avatar" src={avatarUrl || "https://i.pravatar.cc/150?img=3"}></img>
            </div>
          </MenuButton>
        }
      >
        <MenuItem onClick={() => navigate("/profile")}>Perfil</MenuItem>
        {/*<MenuItem>Configurações</MenuItem>*/}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default HeaderNav;
