import { Button, Card, Flex, SelectField, Text, TextAreaField, View } from "@aws-amplify/ui-react";
import React, { useEffect, useState } from "react";
import { InputTextField } from "../../components/Inputs";
import { toast } from "react-toastify";
import { serviceAPI } from "../../api/service.api";
import { storageAPI } from "../../api/amplifyStorage.api";
import Loading from "../../components/Loading";
import { redirect, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import DatePickerField from "../../components/DatePicker/DatePickerField";
// import { isValidDate } from "../../utils/functions";

type TModification = {
  title: string,
  description: string,
  when: string,
  isValidated: boolean,
  receipt: string,
  carServicesId: string
}

const defaultValues = {
  title: '',
  description: '',
  when: '',
  isValidated: false,
  receipt: '',
  carServicesId: ''
}

const ModificationEdit = () => {
  const { state: props } = useLocation();
  const { isAuthenticating, isAuthenticated, user, fetchAuthUser } = useAuth();
  const [formFields, setFormFields] = useState<TModification>(defaultValues);
  const [fieldsWithError, setFieldsWithError] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [saved, setSaved] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date(formFields.when));

  console.log('modifications', {fieldsWithError, user})

  const loadClose = () => setOpen(false);
  const loadToggle = () => setOpen(!open);

  const setField = (fieldValue, fieldName) => {
    setFormFields({
        ...formFields,
        [fieldName]: fieldValue,
    });
  }

  const handleDateChange = date => {
    setSelectedDate(date);
    setFormFields({
      ...formFields,
      when: date,
    });
  };

  const validFormFields = () => {
    let isValid = true;
    const errors: string[] = [];
    Object.entries(formFields).forEach(([key, value]) => {
        if (key === 'title') {
            if (value === '' || !value) {
                isValid = false;
                errors.push(key)
            }
        }
        if (key === 'description') {
            if (value === '' || !value) {
                isValid = false;
                errors.push(key)
            }
        }
        // if (key === 'when') {
        //     if (value === '' || !value) {
        //         isValid = false;
        //         errors.push(key)
        //     }
        // }
        if (key === 'carServicesId') {
            if (value === '' || !value) {
                isValid = false;
                errors.push(key)
            }
        }
    });
    console.log('fieldswitherror', errors)
    setFieldsWithError([...errors]);
    return isValid;
  }

  const mapperFormFields = async () => {
    return {
      ...formFields,
      when: new Date(formFields?.when).toISOString() || new Date().toISOString(),
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    loadToggle();
    setSubmitEnabled(false);
    if (validFormFields()) {
      const formData = await mapperFormFields();
      await submit(props.serviceData.id, { ...formData });
    } else toast.warn('Todos os campos devem ser preenchidos');
    setSubmitEnabled(true);
    loadClose();
  }

  const submit = async (id, data) => {
    const newService = await serviceAPI.update(id, data);
    if (newService) {
      toast.success('Modificação editada');
      setSaved(true);
    } else toast.error('Desculpe, ocorreu um erro');
  }

  const handleUpload = async (e) => {
    loadToggle();
    setSubmitEnabled(false);
    const file = e.target.files[0];
    if (!file) {
      loadClose();
      setSubmitEnabled(false);
      return;
    }

    const fileExtension = `${file.name.split('.').pop()}`;
    const result = await storageAPI.upload(file, fileExtension)
    if (!result) toast.error('Desculpe, ocorreu um erro ao fazer upload')
    else {
      setFormFields({ ...formFields, receipt: result.key });
      toast.success('Upload feito com sucesso!');
    }
    setSubmitEnabled(true);
    loadClose();
  }

  useEffect(() => {
    setSaved(false);
    fetchAuthUser();
  }, [saved]);

  useEffect(() => {
    if (isAuthenticating || !isAuthenticated) {
      redirect("/login");
    }
    setFormFields(props.serviceData);
  }, [isAuthenticating, isAuthenticated]);

  if (isAuthenticating || !isAuthenticated) {
    return <Loading />;
  }

  return (
    <>
    {open && <Loading />}
    <div>
      <h2>Nova Modificação</h2>
    </div>
    <View columnSpan={[1, 1, 1, 2]} rowSpan={{ base: 3, large: 4 }}>
      <Card borderRadius="15px">
        <Flex as="form" direction="column" width="100%" onSubmit={handleSubmit}>
          <InputTextField
            label="Titulo"
            name="title" 
            placeholder="Exemplo: Modificação elétrica"
            isRequired={true}
            value={formFields.title}
            onChange={(event) => setField(event.target.value, event.target.name)}
          />

          <SelectField 
            label="Veículo"
            id="carServicesId" 
            name="carServicesId"
            placeholder="Selecione o veículo"
            value={formFields.carServicesId}
            onChange={(event) => {
              setField(event.target.value, event.target.name);
              setFieldsWithError([]);
            }}
            disabled
          >
            <option
              key={`${props['serviceData']['carData']['id']}-user-vehicle-select`}
              value={props['serviceData']['carData']['id']}
            >
              {`${props['serviceData']['carData']['brand']} ${props['serviceData']['carData']['model']} - ${props['serviceData']['carData']['plate']}`}
            </option>
          </SelectField>

          <TextAreaField
            label={
              <Text>
                Descrição
                <Text as="span" fontSize="0.8rem" color="#f69697">
                  {' '}
                  (campo obrigatório)
                </Text>
              </Text>
            } 
            name="description" 
            descriptiveText="Descreva o que foi feito"
            placeholder="Exemplo: Reparo do carburador, troca de velas..."
            isRequired={true}
            value={formFields.description}
            rows={5}
            onChange={(event) => setField(event.target.value, event.target.name)}
          />

          {/* <InputTextField
            label="Data"
            name="when" 
            placeholder="05/10/2022"
            isRequired={true}
            value={formFields.when}
            onChange={(event) => setField(event.target.value, event.target.name)}
          /> */}

          <DatePickerField
            selectedDate={selectedDate} 
            handleDateChange={handleDateChange} 
            label="Data"
          />

          {/* TODO: limit file size. https://w3collective.com/restrict-file-size-javascript/ */}
          <div className="form-group add-file">
            <label>Adicione a Nota Fiscal</label>
            <input 
              type="file" 
              className="form-control-file" 
              id="receipt" 
              name="receipt"
              accept="image/png, image/jpeg, application/msword, application/pdf"
              onChange={handleUpload}
              onLoad={(e) => (e.target as HTMLInputElement).value = ''}
            />
          </div>

          <Button
            type="submit"
            variation="primary"
            isFullWidth={true}
            disabled={!submitEnabled}
          >
            Enviar
          </Button>
        </Flex>
      </Card>
    </View>
    </>
  );
};

export default ModificationEdit;