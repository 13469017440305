import React from 'react';
import { Icon, Text } from '@aws-amplify/ui-react';

const VehicleInfoItem = ({ icon, title, value }) => {
    return (
        <div className='item' style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
            <div className='item-icon' style={{minWidth:"0px"}}>
                <Icon style={{width:"50px", height:"auto"}}>
                    {icon}
                </Icon>
            </div>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", marginLeft: "2px", minWidth:"0px"}}>
                <div className='title' style={{fontWeight: 700, fontSize: "16px"}}>
                    <Text>{title}:</Text>
                </div>
                <div className='value' style={{fontWeight: 400, fontSize: "20px"}}>
                    <Text>{value}</Text>
                </div>
            </div>
        </div>
    )
}

export default VehicleInfoItem;