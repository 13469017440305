import React from "react";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import PageRouter from "./router/PageRouter";

import { AwsRum, AwsRumConfig } from "aws-rum-web"
import { envConfig } from "./config/EnvConfig";

const enviroment = envConfig.APP_ENV;

if (enviroment === 'production') {
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: envConfig.GUEST_ROLE_ARN,
      identityPoolId: envConfig.IDENTITY_POOL_ID,
      endpoint: envConfig.RUM_ENDPOINT,
      telemetries: ["performance","errors","http"],
      allowCookies: true,
      enableXRay: false
    };
  
    const APPLICATION_ID: string = envConfig.RUM_APPLICATION_ID!;
    const APPLICATION_VERSION: string = envConfig.RUM_APPLICATION_VERSION!;
    const APPLICATION_REGION: string = envConfig.RUM_APPLICATION_REGION!;
  
    const awsRum: AwsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );

    console.log(awsRum)
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}

export default function App() {

  return (
    <>
      <PageRouter />
    </>
  );
}
