import { Storage } from "aws-amplify";
import { v4 as uuid } from 'uuid';

class AmplifyStorage {

  /**
   * Upload file to storage
   * 
   * @param {*} file 
   * @param {*} extension 
   * @returns 
   */
  async upload(file: any, extension: string) {
    try {
      const fileName = `${uuid()}.${extension}`;

      const result = await Storage.put(fileName, file, {
        progressCallback(progress) {
          console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        }
      });
      return result;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  /**
   * Get image from storage
   * 
   * @param {*} key 
   * @returns 
   */
  async getImage(key: string) {
    try {
      return await Storage.get(key, { download: false });
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}

export const storageAPI = new AmplifyStorage();
