import { Card, Flex, Grid, Image, Button, Table, TableBody, TableCell, TableHead, TableRow, View, useTheme, Divider, Text } from '@aws-amplify/ui-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { storageAPI } from '../../api/amplifyStorage.api';
import { userAPI } from '../../api/user.api';
import SportCar from '../../components/Icons/sportCar';
import Drop from '../../components/Icons/drop';
import VehicleInfoItem from '../../components/VehicleInfoItem';
import PaintRoller from '../../components/Icons/paintRoller';
import Calendar from '../../components/Icons/calendar';
import Dollar from '../../components/Icons/dollar';
import { consultAssistant } from '../../services/CarAssistant';
import { carAPI } from '../../api/car.api';
import { normalizeAssistantMessage } from '../../utils/functions';
import Loading from '../../components/Loading';

const defaultMessage: string = 'Nossa IA está gerando uma análise. Assim que estiver pronta ela aparecerá aqui.'
// const defaultSecondMessage: string = 'A análise do seu veículo está quase pronta!';

const VehicleProfile = () => {
  const navigate = useNavigate();
  const { tokens } = useTheme();
  const { userId, carId } = useParams();
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const loadClose = () => setOpen(false);
  const loadToggle = () => setOpen(!open);
  const [userData, setUserData] = useState({});
  const [userCar, setUserCar] = useState<any>({});
  const [carUpdated, setCarUpdated] = useState(false);

  console.log('car profile', { userId, carId, user, userData, userCar })

  const handleAnalisis = async () => {
    loadToggle();
    if (!await isAlreadyAnalised()) {
      await carAPI.update(
        carId!,
        { assistant_input: defaultMessage }
      );
      getAssistantInput(carId);
      setCarUpdated(true);
    }
    loadClose();
  }

  const isAlreadyAnalised = async () => {
    const car = await carAPI.find(carId!);
    if (car && car.assistant_input) return true
    else return false
  }

  const forceCheckAssistant = async (car) => {
    if (!car?.assistant_input || car?.assistant_input === defaultMessage) {
      try {
        const updated = await carAPI.update(
          car.id!,
          { pbt: '' }
        )
        if (updated) setCarUpdated(true);
      } catch (error) {
        console.log('forceCheckAssistant', error)
      }
    }
  }

  const getAssistantInput = (id) => {
    consultAssistant(id);
  }

  const navigateToEditPage = (car) => {
    navigate(`/cars/edit/${car.id}`, { state: { carData: car } });
  };

  const getImage = async (key) => {
    return await storageAPI.getImage(key);
  }

  const getUserData = async (id: string) => {
    const userData = await userAPI.findById(id);
    if (userData) {
      setUserData(userData);
      getUserCarData(userData);
    }
  }

  const getUserCarData = async (user) => {
    let carList = await user.cars.toArray();
    let userCar = carList.find((car) => car.id === carId);
    let modifications = [];
    let photoList:any = [];
    if (userCar && userCar.photos && userCar.photos.length) {
      photoList = await Promise.all(userCar.photos.map(async photo => {
        if (photo) return await getImage(photo);
      }));
    }
    if (userCar && userCar.services) modifications = await userCar.services.toArray();
    if (modifications.length) {
      modifications = await Promise.all(modifications.map(async (mod: any) => {
        const typeInfo = await mod['type'];
        const modData = {
          title: typeInfo?.['title'],
          description: typeInfo?.['description']
        }
        return { ...mod, typeData: modData }
      })) as [];
    }
    let car = { ...userCar, photoList, modifications };
    await forceCheckAssistant(car);
    setUserCar(car);
  }

  const fetchUserData = useCallback(async () => {
    loadToggle();
    await getUserData(userId!);
    loadClose();
  }, []);

  useEffect(() => {
    if (userId && carId) fetchUserData();
    else navigate(-1);
  }, [carId, fetchUserData, navigate, userId, carUpdated]);


  return (
    <>
      {open && <Loading />}
      <div>
        <h2>Perfil do Veículo</h2>
      </div>
      <View maxWidth="100%" padding="0rem" minHeight="100vh">
        <Flex
          direction={{ base: "column", large: "row" }}
          alignItems="flex-start"
          maxWidth="100%"
        >
          <Grid
            templateColumns={{ base: "minmax(80%, 90%)", large: "1fr 1fr 1fr" }}
            templateRows={{ base: "1fr 1fr 1fr", large: "1fr 1fr 1fr" }}
            width="100%"
            gap={tokens.space.xl}
          > 
          {/* here */}
            <View columnSpan={{ base: 1, large: 3 }} rowSpan={1} width="100%" >
              
             
              <Card borderRadius="15px" textAlign={"center"} height="100%">
                {userCar?.['photoList']?.length > 0 ? 
                <div id="carouselExample" className="carousel slide carousel-dark">
                  <div className="carousel-inner">

                  {
                    userCar?.['photoList']?.map((o, index) => {

                      return (
                        <div className={index === 0 ? 'carousel-item active' : 'carousel-item'}>
                          {o && <Image alt='Car Picture'
                          maxHeight="20rem"
                          minHeight="20rem"
                          src={o} 
                          />}
                        </div>
                      )  
                    })
                  }
                                
                  </div>

                  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>

                </div>
               : (
                
                <Button
                  type="button"
                  variation="primary"
                  isFullWidth={true}
                  maxWidth="20rem"
                  onClick={() => navigateToEditPage(userCar)}
                >
                  Adicione uma Foto
                </Button>
              )}         
              </Card> 
            </View>

            <View columnSpan={{ base: 1, large: 3 }} rowSpan={1} width="100%">
              <Card height="100%" borderRadius="15px">
                <Flex direction="column" gap="3rem" justifyContent="space-between" alignItems="stretch" alignContent="center" maxWidth="100%">
                  <div style={{maxWidth: "100%"}}>
                    <Text
                      lineHeight="1.5em"
                      fontWeight={700}
                      fontSize="xxx-large"
                    >
                      {userCar?.['brand']}
                    </Text>
                    <Text
                      lineHeight="1.5em"
                      fontWeight={400}
                      fontSize="2em"
                    >
                      {userCar?.['model']}
                    </Text>
                  </div>

                  <Divider />
                  
                  <Flex direction="row" gap="3rem" maxWidth="100%" wrap="wrap">
                    {userCar?.['mounting'] && <VehicleInfoItem icon={<SportCar/>} title="Montagem" value={userCar?.['mounting']} />}
                    {userCar?.['fuel'] && <VehicleInfoItem icon={<Drop/>} title="Combustível" value={userCar?.['fuel']} />}
                    {userCar?.['color'] && <VehicleInfoItem icon={<PaintRoller/>} title="Cor" value={userCar?.['color']} />}
                    {userCar?.['year'] && <VehicleInfoItem icon={<Calendar/>} title="Ano" value={userCar?.['year']} />}
                    {userCar?.['fipe_value'] && <VehicleInfoItem icon={<Dollar/>} title="Valor FIPE" value={'R$ ' + userCar?.['fipe_value']} />}
                  </Flex>

                  <Button
                    type="button"
                    variation="primary"
                    isFullWidth={true}
                    maxWidth="20rem"
                    onClick={() => navigateToEditPage(userCar)}
                  >
                    Editar Veículo
                  </Button>

                </Flex>
              </Card>
            </View>

            {userCar?.['assistant_input']
            ? (
              <View columnSpan={{ base: 1, large: 3 }} rowSpan={1} width="100%">
                <Card borderRadius="15px" overflow="auto">
                  <p>{normalizeAssistantMessage(userCar?.['assistant_input'])}</p>
                </Card>
              </View>
            ) 
            : carUpdated && (
              <View columnSpan={{ base: 1, large: 3 }} rowSpan={1} width="100%">
                <Card borderRadius="15px" overflow="auto">
                  <Button
                      type="button"
                      variation="primary"
                      isFullWidth={true}
                      maxWidth="20rem"
                      onClick={handleAnalisis}
                    >
                      Gerar análise
                  </Button>
                </Card>
              </View>
            )}

            {userCar && userCar?.['modifications'] && userCar?.['modifications']?.length ? 
            <View columnSpan={{ base: 1, large: 3 }} rowSpan={1} width="100%">
              <Card borderRadius="15px" overflow="auto">
                <Table caption="" highlightOnHover={false}>
                  <TableHead>
                    <TableRow>
                      <TableCell as="th">Modificação</TableCell>
                      <TableCell as="th">Tipo</TableCell>
                      <TableCell as="th">Descrição</TableCell>
                      <TableCell as="th">Data</TableCell>
                      <TableCell as="th">Status</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {userCar?.['modifications'] && userCar?.['modifications']?.length && userCar?.['modifications']?.map((item) => {
                      return (
                        <TableRow
                          key={item["id"] + 'table-services-item'}
                        >
                          <TableCell>{item?.['title']}</TableCell>
                          <TableCell>{item?.['typeData']?.['title']}</TableCell>
                          <TableCell>{item['description']}</TableCell>
                          <TableCell>{item['when']}</TableCell>
                          <TableCell>{item['isValidated'] ? 'Validado' : 'A validar'}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Card>
            </View> : null}
          </Grid>
        </Flex>
      </View>
    </>
  )
}

export default VehicleProfile;