export function maskPhone(phone: string) {
  let phoneNumber = phone;
  phoneNumber = phoneNumber.replace(/\D/g, "");
  phoneNumber = phoneNumber.replace(/^(\d{2})(\d)/g, "($1) $2");
  phoneNumber = phoneNumber.replace(/(\d)(\d{4})$/, "$1-$2");
  return phoneNumber;
}


export function formatDate(date: Date) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('/');
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export function isValidDate(dateString: string) {
  if (!dateString.trim()) return false;
  const date = new Date(dateString);
  if (!date) return false;
  return !isNaN(date.getTime());
}

export function normalizeAssistantMessage(message: string) {
  try {
    if (!message.includes("'input':")) return substituteId(message);
    const extractedMessage = extractOutput(message);
    if (extractedMessage) return substituteId(extractedMessage);
    else return message;
  } catch (error) {
    console.log(error)
    return false;
  }
}

function extractOutput(message: string) {
  const regex = /'output'\s*:\s*'([^']*)'/;
  const match = message.match(regex);
  if (match && match.length > 1) {
      const decodedOutput = decodeURIComponent(match[1]);
      return decodedOutput;
  } else {
      return message;
  }
}

function substituteId(message: string) {
  const regex = /(\b[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}\b)/g;
  const substitutedMessage = message.replace(regex, '[ID de Veículo]');
  return substitutedMessage;
}

