import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Amplify, AuthModeStrategyType } from 'aws-amplify';
import awsExports from './aws-exports';

import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from "@aws-amplify/ui-react";
import theme from "./theme";
import { AuthProvider } from "./contexts/AuthContext";
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { envConfig } from "./config/EnvConfig";
import { AmplifyHubProvider } from "./contexts/AmplifyContext";

const hostname = envConfig.REACT_APP_HOST_NAME;
const enviroment = envConfig.APP_ENV;

// Check if you are in localhost or production
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

const signInURI = awsExports.oauth.redirectSignIn.split(',');
const signOutURI = awsExports.oauth.redirectSignOut.split(',');

// console.log({ info: 'DATA', signInURI, signOutURI, host: window.location.hostname, hostname, enviroment })

if (isLocalhost) {
  awsExports.oauth.redirectSignIn = signInURI.find(e => e.includes('localhost'))!;
  awsExports.oauth.redirectSignOut = signOutURI.find(e => e.includes('localhost'))!;
} else if (window.location.hostname === hostname) {
  if (enviroment === 'production') {
    awsExports.oauth.redirectSignIn = signInURI[1];
    awsExports.oauth.redirectSignOut = signOutURI[2];
  } else {
    awsExports.oauth.redirectSignIn = signInURI[0];
    awsExports.oauth.redirectSignOut = signOutURI[1];
  }
} else {
  console.log('This is not possible');
}


Amplify.configure({...awsExports, DataStore: { authModeStrategyType: AuthModeStrategyType.MULTI_AUTH }});

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <AmplifyHubProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      </AmplifyHubProvider>
    </AuthProvider>
  </ThemeProvider>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
