import React, { useEffect, useRef, useState } from "react";
import Loading from "../components/Loading";
import { Auth, DataStore, Hub } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { userAPI } from "../api/user.api";
import { clearDataStore } from "../utils/clearStore";

export interface IAuthContextType {
  user: any;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  unverifiedAccount: { email: string; password: string };
  isAdmin: boolean;
  signIn: (p: { email: string; password: string }) => Promise<any>;
  signOut: () => Promise<any>;
  signUp: (p: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) => Promise<any>;
  confirmAccount: (p: { code: string }) => Promise<any>;
  federatedSignIn: (p: { provider: string, isSignUp?: boolean }) => Promise<any>;
  fetchAuthUser: () => Promise<any>;
  createUserInDontExist: () => void;
}

interface IAuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = React.createContext<IAuthContextType>({
  user: null,
  isAuthenticated: false,
  isAuthenticating: true,
  unverifiedAccount: {
    email: "",
    password: "",
  },
  isAdmin: false,
  signIn: async () => {},
  signOut: async () => {},
  signUp: async () => {},
  confirmAccount: async () => {},
  federatedSignIn: async () => {},
  fetchAuthUser: async () => {},
  createUserInDontExist: () => {},
});

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [user, setUser] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [unverifiedAccount, setUnverifiedAccount] = useState({
    email: "",
    password: "",
  });
  const isInitialMount = useRef(true);

  const createUserInDontExist = async () => {
    console.log('createUserInDontExist')
    let fetchedUser = await Auth.currentAuthenticatedUser();
    let userData = await userAPI.find({ email: fetchedUser.attributes.email });
    if (!userData) {
      userData = await userAPI.create({ name: fetchedUser.attributes.email, email: fetchedUser.attributes.email });
    }
    fetchedUser.userData = userData;
    setUser(fetchedUser);
  }

  /**
   * fetch currently logged-in user using AWS Auth library
   * @returns {Promise<void>}
   */
  const fetchAuthUser = async (eventType?: string) => {
    try {
      console.log(eventType)
      let fetchedUser = await Auth.currentAuthenticatedUser();
      const groups = fetchedUser.signInUserSession.accessToken.payload["cognito:groups"];
      if (groups && groups.includes("admins")) {
        setIsAdmin(true);
      }

      let userData = await userAPI.find({ email: fetchedUser.attributes.email });
      if (eventType && eventType === 'cognitoHostedUI') {
        if (!userData) {
          await userAPI.create({ name: fetchedUser.attributes.email, email: fetchedUser.attributes.email });
          userData = await userAPI.find({ email: fetchedUser.attributes.email });
        }
      }
      
      if (userData) fetchedUser.userData = userData;
      // if (!userData) {
      //   userData = await userAPI.create({ name: fetchedUser.attributes.email, email: fetchedUser.attributes.email });
      //   // throw new Error('User data not found');
      // }
      // fetchedUser.userData = userData;
      
      setIsAuthenticating(false);
      setUser(fetchedUser);
    } catch (err) {
      setIsAuthenticating(false);
      setUser(null);
    }
  };

  useEffect(() => {
    const fetchAndSetUser = async (eventType?: string) => {
      await fetchAuthUser(eventType);
    };
  
    if (isInitialMount.current) {
      console.log('isInitialMount', isInitialMount)
      fetchAndSetUser();
      isInitialMount.current = false;
    }

    // listening for auth change events
    const authListener = Hub.listen(
      "auth",
      async ({ payload: { event, data } }) => {
        console.log('HUB', { event, data})
        switch (event) {
          case "signIn":
            await DataStore.start();
            break;
          case 'cognitoHostedUI':
            await fetchAndSetUser('cognitoHostedUI');
            break;
          case "signOut":
            setUser(null);
            localStorage.removeItem('user');
            await DataStore.clear();
            break;
          case "signIn_failure":
          case "signUp_failure":
          case 'cognitoHostedUI_failure':
            if (user) {
              localStorage.removeItem('user');
              setUser(null);
            }
            break;
          case "signUp":
          case "forgotPassword":
          case "forgotPasswordSubmit":
          case "forgotPasswordSubmit_failure":
          case "forgotPassword_failure":
            break;
          default:
            await fetchAndSetUser();
        }
      }
    );

    // cleanup
    return () => {
      authListener();
    };
  }, []);

  /**
   * log user in w/ email and password
   * @param email
   * @param password
   */
  const signIn = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    await Auth.signIn({ username: email, password });
  };

  /**
   * create new user account w/ email and password
   * @param email
   * @param password
   * @param firstName
   * @param lastName
   */
  const signUp = async ({
    email,
    password,
    firstName,
    lastName,
  }: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) => {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name: `${firstName} ${lastName}`,
      },
    });

    await userAPI.create({ email, name: `${firstName} ${lastName}` });

    setUnverifiedAccount({ email, password });
  };

  /**
   * log user in w/ third party
   * @param provider
   */
  const federatedSignIn = async ({
    provider
  }: {
    provider: string;
  }) => {
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider[provider] });
  };

  /**
   * confirm account using code
   * @param confirmCode
   * @returns {Promise<any>}
   */
  const confirmAccount = async ({ code }: { code: string }) => {
    await Auth.confirmSignUp(unverifiedAccount?.email, code);
    await signIn({
      email: unverifiedAccount?.email,
      password: unverifiedAccount?.password,
    });
  };

  /**
   * logout user
   */
  const signOut = async () => {
    await Auth.signOut();
    await clearDataStore();
  }

  const value = {
    user,
    isAuthenticated: !!user,
    isAuthenticating,
    unverifiedAccount,
    isAdmin,
    signIn,
    signOut,
    signUp,
    confirmAccount,
    federatedSignIn,
    fetchAuthUser,
    createUserInDontExist,
  };

  if (isAuthenticating) {
    return <Loading />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};