import React, { useCallback, useEffect, useState } from "react";
import { Flex, Text } from "@aws-amplify/ui-react";
import { storageAPI } from "../../api/amplifyStorage.api";

interface ProfileHeaderProps {
  name?: string;
  email?: string;
  imageSrc?: string;
}

const ProfileHeader = (props: ProfileHeaderProps) => {
  const [avatarUrl, setAvatarUrl] = useState('');
  console.log('profheader', {props});

  const setAvatarImage = async () => {
    const imageUrl = await getImage(props.imageSrc);
    if (imageUrl) setAvatarUrl(imageUrl);
  }

  const getImage = async (key) => {
    return await storageAPI.getImage(key);
  }

  const callAsyncFunctions = useCallback(async () => {
    await setAvatarImage();
  }, [props.imageSrc]); 

  useEffect(() => {
    if (props.imageSrc) callAsyncFunctions();
  }, [callAsyncFunctions, props.imageSrc]);

  return (
    <>
      <Flex
        direction={{ base: "column", large: "row" }}
        alignItems="flex-start"
      >
        <div className="profile-header-image">
          <img alt="avatar" src={avatarUrl || "https://i.pravatar.cc/150?img=3"}></img>
        </div>
        <div className="profile-header-text">
          <Text variation="primary" fontWeight={600} fontSize="18px">
            {props.name}
          </Text>
          <Text variation="tertiary">{props.email}</Text>
        </div>
      </Flex>
    </>
  );
};

export default ProfileHeader;
