import React from "react";
import { Flex, Text, Button } from "@aws-amplify/ui-react";
import { formatDate, maskPhone } from "../../utils/functions";

interface ProfileInfoProps {
  name?: string;
  email?: string;
  createdAt: string;
  phone?: string;
  openEdit: () => void;
}

const ProfileInformation = (props: ProfileInfoProps) => {
  console.log('profile info', props)

  const maskPhoneNumber = (phone) => {
    if (phone) return maskPhone(phone);
    else return '';
  }

  return (
    <div className="profile-card-content">
      <Text fontWeight="600" fontSize="18px" marginBottom="14px">
        Informações
      </Text>
      <Flex>
        <Text variation="tertiary" fontWeight="600">
          Nome:
        </Text>
        <Text variation="tertiary">{props.name}</Text>
      </Flex>
      <Flex>
        <Text variation="tertiary" fontWeight="600">
          Telefone:
        </Text>
        <Text variation="tertiary">{maskPhoneNumber(props.phone)}</Text>
      </Flex>
      <Flex>
        <Text variation="tertiary" fontWeight="600">
          Email:
        </Text>
        <Text variation="tertiary">{props.email}</Text>
      </Flex>
      <Flex>
        <Text variation="tertiary" fontWeight="600">
          Conta criada em:
        </Text>
        <Text variation="tertiary">{formatDate(new Date(props.createdAt))}</Text>
      </Flex>
      <div className="profile-card-edit">
        <Button marginLeft="auto" onClick={props.openEdit}>Editar</Button>
      </div>
    </div>
  );
};

export default ProfileInformation;
