import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import Signup from "../pages/signup";
import Signin from "../pages/signin";
import ModificationEdit from "../pages/modification/ModificationEdit";
import Modification from "../pages/modification";
import Modifications from "../pages/modifications";
import VehicleEdit from "../pages/vehicle/VehicleEdit";
import Vehicle from "../pages/vehicle";
import Vehicles from "../pages/vehicles";
import Profile from "../pages/profile";
import UsersTable from "../pages/tables/UsersTable";
import Tables from "../pages/tables";
import EditForm from "../pages/forms/EditForm";
import Forms from "../pages/forms";
import Dashboard from "../pages/dashboard";
import Main from "../pages/main";
import Layout from "../components/Layout";
import ProtectedRouteAdmin from "./ProtectedRouteAdmin";
import ProtectedRouteUser from "./ProtectedRouteUser";
import VehicleProfile from "../pages/vehicle/VehicleProfile";
import News from "../pages/news";

const PageRouter = () => {
  return(
    <div>
    {/* Routes nest inside one another. Nested route paths build upon
        parent route paths, and nested route elements render inside
        parent route elements. See the note about <Outlet> below. */}
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<News />} />
        <Route element={<ProtectedRouteAdmin/>}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="admin" element={<Main />} />
        </Route>
        <Route path="forms" element={<Forms />} />
        <Route path="edit-form" element={<EditForm />} />
        <Route path="tables" element={<Tables />} />
        <Route path="users-table" element={<UsersTable />} />
        <Route path="profile" element={<Profile />} />

        <Route element={<ProtectedRouteUser/>}>
          <Route path="cars" element={<Vehicles />}/>
          <Route path="cars/new" element={<Vehicle />}/>
          <Route path="cars/edit/:id" element={<VehicleEdit />}/>
          <Route path="modifications" element={<Modifications />}/>
          <Route path="modifications/new" element={<Modification />}/>
          <Route path="modifications/edit/:id" element={<ModificationEdit />}/>
          <Route path="cars/profile/:userId/:carId" element={<VehicleProfile />} />
        </Route>

        {/* Using path="*"" means "match anything", so this route
            acts like a catch-all for URLs that we don't have explicit
            routes for. */}
        <Route path="*" element={<NoMatch />} />
      </Route>
      <Route path="/login" element={<Signin />} />
      <Route path="/signup" element={<Signup />} />
    </Routes>
  </div>
  )
}


function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default PageRouter;
