import { IdentifierFieldOrIdentifierObject, ModelInit, PersistentModelMetaData } from "@aws-amplify/datastore";
import { LazyService, Service } from "../models";
import { DataStore } from "aws-amplify";

class ServiceAPI {

  /**
   * Create service
   * 
   * @param {*} data 
   */
  async create(data: ModelInit<LazyService>) {
    try {
      const newService = await DataStore.save(
        new Service({
          ...data, isValidated: false
        })
      );
      console.log('service saved successfully!', newService)
      return newService;
    } catch (error) {
      console.log('Error saving service', error)
      return false;
    }
  }

  /**
   * Update service
   * 
   * OBS: Models in DataStore are immutable. To update a record you must use the copyOf function to apply updates to the item's fields rather than mutating the instance directly.
   * 
   * @param {*} id 
   * @param {*} data 
   */
  async update(id: IdentifierFieldOrIdentifierObject<LazyService, PersistentModelMetaData<LazyService>>, data: { [x: string]: any; }) {
    try {
      const service = await DataStore.query(Service, id);
      let updatedService;
      if (service) {
        updatedService = await DataStore.save(
          Service.copyOf(service, updated => {
            for(const property in data) {
              updated[property] = data[property]
            }
          })
        )

        console.log('service updated successfully!', updatedService)
        return updatedService;
      } else throw new Error(`Service ${id} not found`);
    } catch (error) {
      console.log('Error updating service', error)
      return false
    }
  }

  /**
   * Get service
   * 
   * @param {*} id 
   */
  async find(id: IdentifierFieldOrIdentifierObject<LazyService, PersistentModelMetaData<LazyService>>) {
    try {
      const service = await DataStore.query(Service, id);
      console.log('service found!', service)
      return service;
    } catch (error) {
      console.log('Error getting service', error)
      return false;
    }
  }

  /**
   * Get services
   * @param {*} userId
   */
  async list(userId: string) {
    try {
      const services = await DataStore.query(Service, s => s.car.userCarsId.eq(userId));
      console.log('service found!', services)
      return services;
    } catch (error) {
      console.log('Error getting service', error)
      return false;
    }
  }

  /**
   * Delete a service
   * 
   * @param {*} id 
   */
  async delete(id: IdentifierFieldOrIdentifierObject<LazyService, PersistentModelMetaData<LazyService>>) {
    try {
      const serviceToDelete = await DataStore.query(Service, id);
      let serviceDeleted: LazyService;
      if (serviceToDelete) {
        serviceDeleted = await DataStore.delete(serviceToDelete);
        console.log('service deleted!', serviceDeleted)
        return true
      } else throw new Error(`Service ${id} not found`);
    } catch (error) {
      console.log('Error getting service', error)
      return false;
    }
  }

  /**
   * Get all services
   */
  async listAll() {
    try {
      const services = await DataStore.query(Service);
      return services;
    } catch (error) {
      console.log('Error getting service', error)
      return [];
    }
  }
}

export const serviceAPI = new ServiceAPI();