export const envConfig = {
  FIPE_API_KEY: process.env.REACT_APP_FIPE_API_KEY,
  FIPE_API_HOST: process.env.REACT_APP_FIPE_API_HOST || 'https://5qwkey5lgdgahbax3ajcuwig6a0aytee.lambda-url.us-east-1.on.aws',
  FIPE_API_ENABLED: process.env.REACT_APP_FIPE_API_ENABLED || true,
  CAR_ASSISTANT_HOST: process.env.REACT_APP_CAR_ASSISTANT_HOST || 'https://dtecfscqmkslkhnfwkz5o5pgnm0huqjc.lambda-url.us-east-1.on.aws',
  VERSION: process.env.REACT_APP_VERSION || '1.0.0',
  APP_ENV: process.env.REACT_APP_ENV || 'development',
  REACT_APP_HOST_NAME: process.env.REACT_APP_HOST_NAME || 'd2zknoci7yqp00.cloudfront.net',
  // AWS CloudWatch RUM vars
  GUEST_ROLE_ARN: process.env.REACT_APP_RUM_GUEST_ROLE_ARN,
  IDENTITY_POOL_ID: process.env.REACT_APP_RUM_IDENTITY_POOL_ID,
  RUM_ENDPOINT: process.env.REACT_APP_RUM_ENDPOINT,
  RUM_APPLICATION_ID: process.env.REACT_APP_RUM_APPLICATION_ID,
  RUM_APPLICATION_VERSION: process.env.REACT_APP_RUM_APPLICATION_VERSION,
  RUM_APPLICATION_REGION: process.env.REACT_APP_RUM_APPLICATION_REGION,
}