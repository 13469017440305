import { IdentifierFieldOrIdentifierObject, ModelInit, PersistentModelMetaData } from "@aws-amplify/datastore";
import { LazyServiceType, ServiceType } from "../models";
import { DataStore } from "aws-amplify";

class ServiceTypeAPI {

  /**
   * Create new service type
   * 
   * @param {*} data 
   */
  async create(data: ModelInit<LazyServiceType>) {
    try {
      const newService = await DataStore.save(
        new ServiceType({
          ...data
        })
      );
      console.log('service type saved successfully!', newService)
      return newService;
    } catch (error) {
      console.log('Error saving service type', error)
      return false;
    }
  }

  /**
   * Get service type
   * 
   * @param {*} id 
   */
  async find(id: IdentifierFieldOrIdentifierObject<LazyServiceType, PersistentModelMetaData<LazyServiceType>>) {
    try {
      const service = await DataStore.query(ServiceType, id);
      console.log('service type found!', service)
      return service;
    } catch (error) {
      console.log('Error getting service type', error)
      return false;
    }
  }

  /**
   * Get service types
   */
  async list() {
    try {
      const serviceTypes = await DataStore.query(ServiceType);
      const toAdd = [
        { title: "Preditiva", description: "Possui o objetivo principal de antecipar e encontrar a raiz de problemas" },
        { title: "Preventiva", description: "Ação planejada e sistemática de revisão, controle e monitoramento dos equipamentos" },
        { title: "Corretiva", description: "Reparo após alguma inconsistência ou falha total" },
        { title: "Detectiva", description: "Possui o objetivo de revelar ou descobrir algum problema que não está evidente" },
    ]
      if (!serviceTypes || serviceTypes.length === 0) {
        console.log('ADDING TYPES')
        const serviceTypesPromises = toAdd.map(async (item) => {
            return await DataStore.save(
                new ServiceType(item)
            )
        })
        const resultPromises = await Promise.all(serviceTypesPromises);
        console.log(resultPromises)
      }
      console.log('service found!', serviceTypes)
      return serviceTypes;
    } catch (error) {
      console.log('Error getting service', error)
      return false;
    }
  }

  /**
   * Delete a service type
   * 
   * @param {*} id 
   */
  async delete(id: IdentifierFieldOrIdentifierObject<LazyServiceType, PersistentModelMetaData<LazyServiceType>>) {
    try {
      const serviceTypeToDelete = await DataStore.query(ServiceType, id);
      let serviceTypeDeleted: LazyServiceType;
      if (serviceTypeToDelete) {
        serviceTypeDeleted = await DataStore.delete(serviceTypeToDelete);
        console.log('service type deleted!', serviceTypeDeleted)
        return true
      } else throw new Error(`Service type ${id} not found`);
    } catch (error) {
      console.log('Error getting service type', error)
      return false;
    }
  }
}

export const serviceTypeAPI = new ServiceTypeAPI();