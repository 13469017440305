import axios from "axios"
import { envConfig } from "../config/EnvConfig"

export const consultAssistant = (id: string) => {
    try {
        const input = `analise o veículo de id ${id} e seu historico e me de um parecer geral dele, me diga também seus prós e contras e me oriente sobre como mantê-lo conservado e possíveis ações para valorizá-lo, me informe também o preço dele na tabela fipe, responda em português brasileiro`
        axios.post(
            `${envConfig.CAR_ASSISTANT_HOST}`,
            { input: input },
            { 
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );
    } catch (error) {
        console.log(error)
    }
}
