import { IdentifierFieldOrIdentifierObject, ModelInit, PersistentModelMetaData } from "@aws-amplify/datastore";
import { LazyUser, User } from "../models";
import { API, DataStore, syncExpression } from "aws-amplify";
import { DeleteUserMutation, ListUsersQuery } from "../API";
import { GraphQLQuery } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

class UserAPI {

  /**
   * Create User
   * 
   * @param {*} data 
   */
  async create(data: ModelInit<LazyUser>) {
    try {
      const { email } = data
      const user:LazyUser[] = await DataStore.query(
        User, u => u.email.eq(email)
      )

      const userGql = await API.graphql<GraphQLQuery<ListUsersQuery>>({
        query: queries.listUsers, variables: { filter: { email: { eq: email }, _deleted: { attributeExists: false } } }
      })
      console.log(userGql)

      if ((user && user.length > 0) || (userGql && userGql.data && userGql.data.listUsers && userGql.data.listUsers.items && userGql.data.listUsers.items.length > 0)) {
        throw new Error('User already exists');
      }

      const newUser = await DataStore.save(
        new User({
          ...data
        })
      );
      console.log('User saved successfully!', newUser)

      // await this.checkDuplicates({ email, id: newUser.id });

      return newUser;
    } catch (error) {
      console.log('Error saving user', error)
      return false;
    }
  }

  /**
   * Update User
   * 
   * OBS: Models in DataStore are immutable. To update a record you must use the copyOf function to apply updates to the item's fields rather than mutating the instance directly.
   * 
   * @param {*} id 
   * @param {*} data 
   */
  async update(id: IdentifierFieldOrIdentifierObject<LazyUser, PersistentModelMetaData<LazyUser>>, data: { [x: string]: any; }) {
    try {
      const user = await DataStore.query(User, id);
      let updatedUser: LazyUser;
      if (user) {
        updatedUser = await DataStore.save(
          User.copyOf(user, updated => {
            for(const property in data) {
              updated[property] = data[property]
            }
          })
        );

        console.log('User updated successfully!', updatedUser)
        return updatedUser;
      } else throw new Error(`User ${id} not found`);
    } catch (error) {
      console.log('Error updating user', error)
      return false;
    }
  }

  /**
   * Get User
   * 
   * @param {*} data 
   */
  async find(data: { email: string; }) {
    try {
      const { email } = data;

      console.log('finding user: ', email)
      let userLocal = await DataStore.query(
        User, u => u.email.eq(email)
      );
      console.log('local user', userLocal)
      const userGql = await API.graphql<GraphQLQuery<ListUsersQuery>>({
        query: queries.listUsers, variables: { filter: { email: { eq: email } } }
      })
      console.log('cloud user', userGql)

      if (!Array.isArray(userLocal) || !userLocal[0]) throw new Error('User not found');

      if ((!userGql || userGql.data?.listUsers?.items.length === 0)) {
        DataStore.configure({
          syncExpressions: [
            syncExpression(User, () => {
              return user => user.email.eq('email');
            }),
          ]
        });
      }

      return userLocal[0];
    } catch (error) {
      console.log('Error getting user', error)
      return false;
    }
  }

  /**
   * Get User by id
   * 
   * @param {*} id 
   */
  async findById(id: string) {
    try {
      const user = await DataStore.query(User, id);
      return user;
    } catch (error) {
      console.log('Error getting user', error)
      return false;
    }
  }

  /**
   * Delete a User
   * 
   * @param {*} id 
   */
  async delete(id: IdentifierFieldOrIdentifierObject<LazyUser, PersistentModelMetaData<LazyUser>>) {
    try {
      const userToDelete = await DataStore.query(User, id);
      if(userToDelete) {
        const userDeleted = await DataStore.delete(userToDelete);
        console.log('User deleted!', userDeleted)
        return true;
      } else throw new Error(`User ${id} not found`);
    } catch (error) {
      console.log('Error removing user', error)
      return false;
    }
  }

  async checkDuplicates(data: { email: string, id: string }) {
    try {
      console.log('check duplicates')
      const { email, id } = data;
      let userLocal = await DataStore.query(
        User, u => u.email.eq(email)
      );
      console.log('local user', userLocal)
      const userGql = await API.graphql<GraphQLQuery<ListUsersQuery>>({
        query: queries.listUsers, variables: { filter: { email: { eq: email } } }
      })
      console.log('cloud user', userGql)

      if (userGql && userGql.data?.listUsers?.items && userGql.data?.listUsers?.items.length > 1) {
        const toDeleteGql = userGql.data?.listUsers?.items.find(e => e?.id !== id);
        if (toDeleteGql) {
          console.log('toDeleteGql', toDeleteGql)
          const deletedGql = await API.graphql<GraphQLQuery<DeleteUserMutation>>({
            query: mutations.deleteUser,
            variables: { input: { id: toDeleteGql.id } }
          });
          console.log('deletedGql', deletedGql)
        }
      }

      if (userLocal && userLocal.length > 1) {
        const toDeleteLocal = userLocal.find(e => e?.id !== id);
        if (toDeleteLocal) {
          console.log('toDeleteLocal', toDeleteLocal)
          const deletedLocal = await DataStore.delete(toDeleteLocal);
          console.log('deletedLocal', deletedLocal)
        }
      }

      console.log('SYNC')
      DataStore.configure({
        syncExpressions: [
          syncExpression(User, () => {
            return user => user.email.eq('email');
          }),
        ]
      })
    } catch (error) {
      console.log('Error checking duplicates', error)
      return false;
    }
  }

  async removeDuplicates (data: { email: string, id: string }) {
    try {
      const { email, id } = data;

      console.log('finding user: ', email)
      let usersLocal = await DataStore.query(
        User, u => u.email.eq(email)
      );
      console.log('local user', usersLocal)
      const usersGql = await API.graphql<GraphQLQuery<ListUsersQuery>>({
        query: queries.listUsers, variables: { filter: { email: { eq: email } } }
      })
      console.log('cloud user', usersGql)

      if (Array.isArray(usersLocal) && usersLocal.length > 1) {
        const deletePromises = usersLocal.map(async (user) => {
          if (user.id !== id) {
            console.log('delete user ', user)
            await DataStore.delete(user);
          }
        })

        await Promise.all(deletePromises);
      }
    } catch (error) {
      console.log('Error removing duplicates', error)
    }
  }
}

export const userAPI = new UserAPI();