import React, { useEffect, useState } from "react";
import { View, Flex, useTheme } from "@aws-amplify/ui-react";
import ProfileHeader from "./ProfileHeader";
import ProfileInformation from "./ProfileInformation";
//import ProfileSettings from "./ProfileSettings";
import "./Profile.css";
import Loading from "../../components/Loading";
import { redirect } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import ProfileEdit from "./ProfileEdit";

const Profile = () => {
  const { tokens } = useTheme();
  const { isAuthenticating, isAuthenticated, user, fetchAuthUser } = useAuth();
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  console.log('profile', { user })

  const hendleOpenEdit = () => {
    setIsOpenEdit(true);
  }

  const hendleCloseEdit = () => {
    setIsOpenEdit(false);
    fetchAuthUser();
  }

  useEffect(() => {
    if (isAuthenticating || !isAuthenticated) {
      redirect("/login");
    }

    fetchAuthUser();
  }, [isAuthenticating, isAuthenticated]);

  if (isAuthenticating || !isAuthenticated) {
    return <Loading />;
  }

  return (
    <>
      <div>
        <h2>Perfil</h2>
      </div>
      <View maxWidth="100%" padding="0rem" minHeight="100vh">
        <Flex
          direction={{ base: "column", large: "row" }}
          alignItems="flex-start"
          gap={tokens.space.xl}
          marginBottom="30px"
        >
          <View
            backgroundColor="var(--amplify-colors-white)"
            borderRadius="6px"
            width={{ base: "100%", large: "100%" }}
            padding="1rem"
          >
            <ProfileHeader email={user.userData.email} name={user.userData.name} imageSrc={user.userData.avatar} />
          </View>
        </Flex>

        <Flex
          direction={{ base: "column", large: "row" }}
          gap={tokens.space.xl}
          alignItems="flex-start"
        >
          <View
            backgroundColor="var(--amplify-colors-white)"
            borderRadius="6px"
            width={{ base: "100%", large: "40%" }}
            padding={{ base: "1em", large: "1.5rem" }}
          >
            <ProfileInformation
              createdAt={user.userData.createdAt}
              email={user.userData.email}
              name={user.userData.name}
              phone={user.userData.phone}
              openEdit={hendleOpenEdit}
            />
          </View>
          {/*<View
            backgroundColor="var(--amplify-colors-white)"
            borderRadius="6px"
            width={{ base: "100%", large: "40%" }}
            padding={{ base: "1em", large: "1.5rem" }}
          >
            <ProfileSettings />
          </View>*/}
        </Flex>
      </View>

      {isOpenEdit && <ProfileEdit
        isOpen={isOpenEdit}
        onClose={hendleCloseEdit}
        userData={user.userData}
      />}
    </>
  );
};

export default Profile;
