import { Button, Flex, View } from '@aws-amplify/ui-react';
import React, { useState, useRef, useEffect } from 'react';
import { InputTextField } from '../../components/Inputs';
import { toast } from 'react-toastify';
import { userAPI } from '../../api/user.api';
import { storageAPI } from '../../api/amplifyStorage.api';
import Modal from '../../components/Modal/Modal';
import DatePickerField from '../../components/DatePicker/DatePickerField';
import { parse } from 'date-fns';

interface TUserData {
  id: string;
  name?: string;
  phone?: string;
  avatar?: string;
  birth?: string;
}

interface ProfileEditProps {
  userData: TUserData;
  isOpen: boolean;
  onClose: () => void;
}

const ProfileEdit = (props: ProfileEditProps) => {
  const [formFields, setFormFields] = useState<TUserData>(props.userData);
  const [fieldsWithError, setFieldsWithError] = useState<string[]>([]);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [open, setOpen] = useState(false);
  const focusInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedDate, setSelectedDate] = useState(props?.userData?.birth ? parse(props?.userData?.birth, 'dd/MM/yyyy', new Date()) : new Date());

  console.log('profile edit', { props, fieldsWithError })

  const loadClose = () => setOpen(false);
  const loadToggle = () => setOpen(!open);

  const setField = (fieldValue, fieldName) => {
    setFormFields({
        ...formFields,
        [fieldName]: fieldValue,
    });
  }

  const handleDateChange = date => {
    setSelectedDate(date);
    setFormFields({
      ...formFields,
      birth: date,
    });
  };

  const validFormFields = () => {
    let isValid = true;
    const errors: string[] = [];
    Object.entries(formFields).forEach(([key, value]) => {
        if (key === 'name') {
            if (value === '' || !value) {
                isValid = false;
                errors.push(key)
            }
        }
    });
    setFieldsWithError([...errors]);
    return isValid;
  }

  const mapperFormFields = async () => {
    return {
      ...formFields,
      birth: formFields?.birth ? new Date(formFields?.birth).toISOString() : '',
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    loadToggle();
    setSubmitEnabled(false);
    if (validFormFields()) {
      const formData = await mapperFormFields();
      await submit(props.userData.id, { ...formData });
    } else toast.warn('Os campos obrigatórios devem ser preenchidos');
    setSubmitEnabled(true);
    loadClose();
  }

  const submit = async (id, data) => {
    const updatedUser = await userAPI.update(id, data);
    if (updatedUser) {
      toast.success('Perfil atualizado');
      props.onClose();
    } else toast.error('Desculpe, ocorreu um erro');
  }

  const handleUpload = async (e) => {
    loadToggle();
    setSubmitEnabled(false);
    const file = e.target.files[0];
    if (!file) {
      loadClose();
      setSubmitEnabled(true);
      return;
    }

    const fileExtension = `${file.name.split('.').pop()}`;
    const result = await storageAPI.upload(file, fileExtension)
    if (!result) toast.error('Desculpe, ocorreu um erro ao fazer upload')
    else {
      setFormFields({ ...formFields, avatar: result.key });
      toast.success('Upload feito com sucesso!');
    }
    setSubmitEnabled(true);
    loadClose();
  }

  useEffect(() => {
    if (props.isOpen && focusInputRef.current) {
      setTimeout(() => {
        focusInputRef.current!.focus();
      }, 0);
    }
  }, [props.isOpen]);

  const modalContent = (
    <View
      backgroundColor="var(--amplify-colors-white)"
      borderRadius="6px"
      width={{ base: "100%", large: "100%" }}
      padding="1rem"
    >
      <Flex as="form" direction="column" width="100%" onSubmit={handleSubmit}>
        <InputTextField
          label="Nome"
          name="name"
          isRequired={true}
          value={formFields.name}
          placeholder=''
          onChange={(event) => setField(event.target.value, event.target.name)}
        />

        <InputTextField
          label="Telefone"
          name="phone"
          isRequired={true}
          value={formFields.phone}
          placeholder=''
          onChange={(event) => setField(event.target.value, event.target.name)}
        />

        {/* <InputTextField
          label="Data de Nascimento"
          name="birth"
          isRequired={true}
          value={formFields.birth}
          placeholder=''
          onChange={(event) => setField(event.target.value, event.target.name)}
        /> */}

        <DatePickerField
          selectedDate={selectedDate} 
          handleDateChange={handleDateChange} 
          label="Data"
        />

        <div className="form-group add-file">
          <label>Adicione uma foto</label>
          <input 
            type="file" 
            className="form-control-file" 
            id="avatar" 
            name="avatar"
            accept="image/png, image/jpeg, application/msword, application/pdf"
            onChange={handleUpload}
            onLoad={(e) => (e.target as HTMLInputElement).value = ''}
          />
        </div>

        <Button
          type="submit"
          variation="primary"
          isFullWidth={true}
          disabled={!submitEnabled}
        >
          Enviar
        </Button>
      </Flex>
    </View>
  )

  return (
    <>
      <Modal
        hasCloseBtn={true}
        isOpen={props.isOpen}
        onClose={props.onClose}
        children={modalContent}
      />
    </>
    
  )
}

export default ProfileEdit;