import React from "react";
import useAuth from "../hooks/useAuth";
import { Outlet, useNavigate } from "react-router-dom";

const ProtectedRouteUser = (props) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  if (isAuthenticated) return <Outlet {...props} />;
  else navigate("/login", { replace: true });
};

export default ProtectedRouteUser;