import { Flex, View, useTheme, PasswordField } from "@aws-amplify/ui-react";
import { InputTextField } from "../../components/Inputs";
import React, { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import "../../styles/loginPage.css";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";
import LoginLogo from "../../images/full-logo-center.png";
import { authErrors } from "../../utils/errors";
import { envConfig } from "../../config/EnvConfig";

const Signin = () => {
  const navigate = useNavigate();
  const { tokens } = useTheme();
  const { signIn, federatedSignIn, isAuthenticated, isAuthenticating } = useAuth();
  const [loginValues, setLoginValues] = useState({ email: '', password: '' });

  const setValues = (name: string, value: string) => {
    setLoginValues({
      ...loginValues,
      [name]: value
    })
  }

  const handleLogin = async (provider?: string) => {
    try {
      if (!provider) await signIn(loginValues);
      else await federatedSignIn({ provider });
      navigate("/", { replace: true });
    } catch (error) {
      let errorMessage = authErrors[error?.['name']]
      if (errorMessage) toast.error(errorMessage);
      else console.log('SignIn Error', error)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/", { replace: true });
    }
  }, [isAuthenticated]);

  if (isAuthenticating || isAuthenticated) {
    return <Loading />;
  }

  return (
    <>
      <View
        maxWidth="100%" 
        padding="0rem" 
        minHeight="100vh" 
        backgroundColor="var(--amplify-colors-background-secondary)"
      >
        <Flex
          direction={{ base: "column", large: "row" }}
          alignItems="flex-start"
          gap={tokens.space.xl}
          marginBottom="10%"
          justifyContent="center"
        >
          <View
            backgroundColor="var(--amplify-colors-background-secondary)"
            borderRadius="10px"
            maxWidth="500px"
            maxHeight="700px"
            width="30%"
            margin="10px auto"
            padding="1rem"
            display="flex"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <div className='login-content'>

            <div className="d-flex login-form-content">
              <div className="p-5 shadow-sm rounded-3 login-case">
                <div style={{width: "100%"}} className="a">
                  <img 
                    alt="logo" 
                    src={LoginLogo} 
                    style={{
                      width: '100%', 
                      height: '150px', 
                      objectFit: 'contain',
                    }} 
                  />
                </div>
                
                
                <form>

                  <div className="mb-2">
                    <InputTextField
                      label="E-mail"
                      name="email"
                      placeholder=""
                      isRequired={false}
                      aria-describedby="emailHelp"
                      value={loginValues.email}
                      onChange={(e) => setValues(e.target.name, e.target.value)}
                    />
                  </div>

                  <div className="mb-2">
                    <PasswordField
                      label="Senha"
                      name="password"
                      isRequired={true}
                      value={loginValues.password}
                      onChange={(e) => setValues(e.target.name, e.target.value)}
                    />
                  </div>

                  <p className="small forget-passowrd-text"><a className="text-primary" href="forget-password.html">Esqueceu a senha?</a></p>
                  
                  <div className="d-grid login-button-div">
                      <button
                        className="btn btn-primary btn-login-page"
                        type="button"
                        onClick={() => handleLogin()}
                      >
                        Login
                      </button>
                  </div>

                </form>

                <div className="mt-3">
                    <p className="mb-0  text-center create-acount">Não possui conta? <Link to="/signup" className="text-primary fw-bold">Criar</Link></p>
                </div>
                    
                <div className='or-div'>
                  <div className='ou-bar-div'>
                    <div className='ou-bar' />
                  </div>

                  <div className='or-content-box'>
                    <p className="mb-0  text-center create-acount">Ou</p>
                  </div>

                  <div className='ou-bar-div'>
                    <div className='ou-bar' />
                  </div>
                </div>

                <div className='social-login-div'>

                    {/* <button type="button" className="btn login-btn-facebook btn-social-login btn-primary" onClick={() => handleLogin('Facebook')}>
                      Facebook
                    </button>   */}

                  <div/>
              
                    <button
                      type="button"
                      className="btn login-btn-google btn-social-login btn-danger"
                      // style={{ width: "100%" }}
                      onClick={() => handleLogin('Google')}
                    >
                      Google
                    </button>

                </div>

                <div style={{ margin: "5px auto", display: "flex", justifyContent: "center" }}>
                  <p>versão {envConfig.VERSION}</p>
                </div>
                
              </div>    

            </div>

            </div>
          </View>
        </Flex>
      </View>
    </>
  )
}

export default Signin;