import React, { useCallback, useEffect, useState } from "react";
import {
  View,
} from "@aws-amplify/ui-react";

import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { serviceAPI } from "../../api/service.api";
import { toast } from "react-toastify";
import { LazyService } from "../../models";
import { observeServices } from "../../subscriptions/ServiceSubscription";
import ItemDetails from "./ItemDetails";
import ModificationsTable from "../../components/Tables/ModificationsTable";
import { formatDate } from "../../utils/functions";

const Main = () => {
  const navigate = useNavigate();
  const { isAuthenticating, isAuthenticated, user, createUserInDontExist } = useAuth();
  const [open, setOpen] = useState(false);
  const [tableItems, setTableItems] = useState([]);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [updated, setUpdated] = useState(false);

  const loadClose = () => setOpen(false);
  const loadToggle = () => setOpen(open);

  const handleCloseDetails = () => {
    setIsDetailsOpen(false);
    setSelectedItem(null);
  }

  const handleSave = async (item, value) => {
    loadToggle();
    try {
      const updated = await serviceAPI.update(
        item.id, 
        {
          isValidated: value, 
          validatedAt: new Date().toISOString()
        }
      );
      if (updated) toast.success('Atualização feita com sucesso');
      setUpdated(true);
    } catch (error) {
      toast.success('Ocorreu um erro.\n'+error);
      handleCloseDetails();
      loadClose();
    }
    handleCloseDetails();
    loadClose();
  }

  const handleClick = (item) => {
    setSelectedItem(item);
    setIsDetailsOpen(true);
  }

  const getPromiseResult = async (promise: Promise<any>) => {
    return await promise;
  }

  const buildTableData = async () => {
    loadToggle();
    try {
      const modificationsData: LazyService[] = await serviceAPI.listAll();
      let modificationsList = [];
      modificationsList = await Promise.all(
        modificationsData.map(async modification => {
          const modType = await getPromiseResult(modification.type);
          const carData = await getPromiseResult(modification.car);
          const userData = await getPromiseResult(carData?.user);

          if (modType && carData && userData) {
            return { 
              ...modification,
              carData,
              userData,
              carPlate: carData?.plate, 
              modType: modType?.title, 
              userEmail: userData?.email,
              isValidated: modification?.validatedAt ? modification?.isValidated ? 'Aprovado' : 'Não aprovado' : 'Não avaliado',
              createdAt: modification?.createdAt ? formatDate(new Date(modification?.createdAt)) : '',
              validatedAt: modification?.validatedAt ? formatDate(new Date(modification?.validatedAt)) : '',
            };
          } else {
            return null;
          }
        })
      ) as [];

      modificationsList = modificationsList.filter(modification => modification !== null) as [];

      setTableItems(modificationsList);
    } catch (error) {
      toast.error('Ocorreu um erro ao carregar as modificações.\n' + error);
    }
    loadClose();
  }

  const callAsyncFunctions = useCallback(async () => {
    await buildTableData();
  }, []);

  useEffect(() => {
    if (isAuthenticating || !isAuthenticated) {
      navigate("/login", { replace: true });
    }
  }, [isAuthenticating, isAuthenticated]);

  useEffect(() => {
    if(isAuthenticated && !user?.userData) {
      createUserInDontExist();
    }
    callAsyncFunctions();
    setUpdated(false);
    observeServices();
  }, [user, updated]);

  if (isAuthenticating || !isAuthenticated) {
    return <Loading />;
  }

  return (
    <>
      {open && <Loading />}

      <div>
        <h2>Modificações</h2>
      </div>
      <View borderRadius="6px" maxWidth="100%" padding="0rem" minHeight="100vh">
        <div>
          <ModificationsTable 
            items={tableItems}
            onClick={handleClick}
          />
        </div>
      </View>

      {isDetailsOpen && (
        <ItemDetails 
          itemData={selectedItem} 
          onClose={handleCloseDetails} 
          onSave={handleSave} 
          isOpen={isDetailsOpen}
        />
      )}
    </>
  );
};

export default Main;
