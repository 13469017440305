import React, { useCallback, useEffect, useState } from "react";
import { Badge, Button, Flex, Heading, Text, View } from "@aws-amplify/ui-react";
import Modal from "../../components/Modal/Modal";

import './ItemDetails.css';
import { storageAPI } from "../../api/amplifyStorage.api";
import { MdFileDownload } from "react-icons/md";
import Loading from "../../components/Loading";

const ItemDetails = ({ itemData, onClose, onSave, isOpen }) => {
  const [fileLink, setFileLink] = useState<any>('');
  const [isLoading, setIsLoading] = useState(false);

  const loadClose = () => setIsLoading(false);
  const loadToggle = () => setIsLoading(true); 

  const handleSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: boolean) => {
    e.preventDefault();
    onSave(itemData, value);
  }

  const getFileLink = async () => {
    loadToggle();
    const file = await storageAPI.getImage(itemData.receipt);
    if (file) setFileLink(file);
    loadClose();
  };

  const callAsyncFunctions = useCallback(async () => {
    await getFileLink();

  }, []);

  useEffect(() => {
    if (itemData && itemData?.receipt) callAsyncFunctions();
  }, []);

  const content = (
    <>
      {isLoading && isOpen && <Loading />}

      <div>
        <h2>Detalhes</h2>
      </div>
      <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        width={{ base: "100%", large: "100%" }}
        padding="1rem"
      >
        <Flex 
          direction="column"
          alignItems="flex-start"
          gap={20}
        >
          <Flex
            className="detail-block"
            direction="column"
            alignItems="flex-start"
          >
            <Flex className="detail-block-header">
              <Heading level={4}>Modificação</Heading>
              {itemData?.validatedAt 
                ? itemData.isValidated === 'Aprovado' 
                  ? (
                  <Badge size="small" variation="success">
                    Aprovado
                  </Badge>
                  ) : (
                    <Badge size="small" variation="warning">
                      Não Aprovado
                    </Badge>
                  )
                : (
                <Badge size="small">
                  Não Avaliado
                </Badge>
              )}
            </Flex>
            <Flex
              className="detail-block"
              direction="column"
              alignItems="flex-start"
            >
              <Flex>
                <Text variation="tertiary" fontWeight="600">
                  Titulo:
                </Text>
                <Text variation="tertiary">
                  {itemData?.title}
                </Text>
                <Text variation="tertiary" fontWeight="600">
                  Tipo:
                </Text>
                <Text variation="tertiary">
                  {itemData?.modType}
                </Text>
              </Flex>
              <Flex>
                <Text variation="tertiary" fontWeight="600">
                  Descrição:
                </Text>
                <Text variation="tertiary">
                  {itemData?.description}
                </Text>
              </Flex>
              <Flex>
                <Text variation="tertiary" fontWeight="600">
                  Arquivo:
                </Text>
                {itemData?.receipt && fileLink
                  ? (
                    <Button variation="primary" isFullWidth as="a" href={fileLink}>
                      <MdFileDownload />
                    </Button>
                  ) : (
                    <Text variation="tertiary">
                      Nenhum arquivo encontrado
                    </Text>
                  )}
              </Flex>
            </Flex>
          </Flex>

          <Flex
            className="detail-block"
            direction="column"
            alignItems="flex-start"
          >
            <Flex className="detail-block-header">
              <Heading level={4}>Veículo</Heading>
            </Flex>
            <Flex className="detail-block-content">
              <Text variation="tertiary" fontWeight="600">
                Veículo:
              </Text>
              <Text variation="tertiary">
                {itemData?.carData?.brand} {itemData?.carData?.model}
              </Text>
              <Text variation="tertiary" fontWeight="600">
                Placa:
              </Text>
              <Text variation="tertiary">
                {itemData?.carData?.plate}
              </Text>
            </Flex>
          </Flex>

          <Flex
            className="detail-block"
            direction="column"
            alignItems="flex-start"
          >
            <Flex className="detail-block-header">
              <Heading level={4}>Usuário</Heading>
            </Flex>
            <Flex
              direction="column"
              alignItems="flex-start"
            >
              <Text variation="tertiary" fontWeight="600">
                Nome:
              </Text>
              <Text variation="tertiary">{itemData?.userData?.name}</Text>
              <Text variation="tertiary" fontWeight="600">
                Email:
              </Text>
              <Text variation="tertiary">{itemData?.userData?.email}</Text>
            </Flex>
          </Flex>

          <Flex 
            justifyContent="center"
            alignItems='center'
            direction="row"
            width="relative.full"
          >
            <Button isFullWidth variation="primary" onClick={(e) => handleSave(e, true)}>Aprovar</Button>
            <Button isFullWidth onClick={(e) => handleSave(e, false)}>Negar</Button>
          </Flex>
          
        </Flex>
      </View>
    </>
  );

  return (
    <Modal
      children={content}
      isOpen={isOpen}
      hasCloseBtn={true}
      onClose={onClose}
    />
  );
}

export default ItemDetails;